import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  CREATE_USER,
  CreateUserAction,
  GET_USER,
  GET_USERS,
  GetUserAction,
  GetUsersAction,
  GetUsersResponse,
  UserResponse,
} from './types'
import {
  getUsersSucceeded,
  getUsersFailed,
  getUserSucceeded,
  getUserFailed,
  createUserSucceeded,
  createUserFailed,
} from './actions'

import userService from '../../services/UserService'
import { message } from "antd";
import * as React from "react";

export function* userSaga() {
  yield all([
    watchGetUsers(),
    watchGetUser(),
    watchCreateUser()
  ])
}

function* watchCreateUser() {
  yield takeLatest(CREATE_USER, handleCreateUser)
}

function* watchGetUsers() {
  yield takeLatest(GET_USERS, handleGetUsers)
}

function* watchGetUser() {
  yield takeLatest(GET_USER, handleGetUser)
}

function* handleCreateUser(action: CreateUserAction) {
  try {
    const data: UserResponse = yield call([userService, userService.createUser], action.createUserRequest);

    yield put(createUserSucceeded(data));
  } catch (error) {
    message.error(error.response.data.title);
    yield put(createUserFailed(`${error.response.data.detail}`))
  }
}

function* handleGetUsers(action: GetUsersAction) {
  try {
    const data: GetUsersResponse = yield call([userService, userService.getUsers], action.getUsersRequest);

    yield put(getUsersSucceeded(data));
  } catch (error) {
    message.error(error.response.data.title);
    yield put(getUsersFailed(`${error.response.data.detail}`))
  }
}

function* handleGetUser(action: GetUserAction) {
  try {
    const data: UserResponse = yield call([userService, userService.getUser], action.getUserRequest);

    yield put(getUserSucceeded(data));
  } catch (error) {
    message.error(error.response.data.title);
    yield put(getUserFailed(`${error.response.data.detail}`))
  }
}