import {
  CREATE_USER,
  CREATE_USER_FAILED,
  CREATE_USER_SUCCEEDED,
  CreateUserRequest,
  GET_USER,
  GET_USER_FAILED,
  GET_USER_SUCCEEDED,
  GET_USERS,
  GET_USERS_FAILED,
  GET_USERS_SUCCEEDED,
  GetUserRequest,
  GetUsersRequest,
  GetUsersResponse,
  UserActionTypes,
  UserResponse
} from './types'

export function createUser(createUserRequest: CreateUserRequest): UserActionTypes {
  return {
    type: CREATE_USER,
    createUserRequest
  }
}

export function createUserSucceeded(createUserResponse: UserResponse): UserActionTypes {
  return {
    type: CREATE_USER_SUCCEEDED,
    createUserResponse
  }
}

export function createUserFailed(error: string): UserActionTypes {
  return {
    type: CREATE_USER_FAILED,
    error
  }
}

export function getUsers(getUsersRequest: GetUsersRequest): UserActionTypes {
  return {
    type: GET_USERS,
    getUsersRequest
  }
}

export function getUsersSucceeded(getUsersResponse: GetUsersResponse): UserActionTypes {
  return {
    type: GET_USERS_SUCCEEDED,
    getUsersResponse
  }
}

export function getUsersFailed(error: string): UserActionTypes {
  return {
    type: GET_USERS_FAILED,
    error
  }
}

export function getUser(getUserRequest: GetUserRequest): UserActionTypes {
  return {
    type: GET_USER,
    getUserRequest
  }
}

export function getUserSucceeded(getUserResponse: UserResponse): UserActionTypes {
  return {
    type: GET_USER_SUCCEEDED,
    getUserResponse
  }
}

export function getUserFailed(error: string): UserActionTypes {
  return {
    type: GET_USER_FAILED,
    error
  }
}
