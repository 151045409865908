import {AxiosInstance} from "axios";
import axiosInstance from "../axiosInstance";
import {resolveApiBaseUrl} from "../environment";
import * as qs from "qs";
import {
    FetchProductSearchItemsPageRequest,
    FetchProductSearchPageResponse
} from "../store/productSearch/types";
import {message} from "antd";

class ProductSearchService {
    axios: AxiosInstance;

    constructor() {
        this.axios = axiosInstance;
    }

    getProductSearch(pageRequest: FetchProductSearchItemsPageRequest): Promise<FetchProductSearchPageResponse> {

        const params = {
            page: pageRequest.page,
            size: pageRequest.size
        } as any;

        if (pageRequest.ean) {
            params["ean"] = pageRequest.ean;
        }

        if (pageRequest.country) {
            params["country"] = pageRequest.country;
        }

        if (pageRequest.language) {
            params["language"] = pageRequest.language;
        }

        return this.axios.get(`/product/search`, {
            baseURL: resolveApiBaseUrl(),
            params: params,
            paramsSerializer: {
                serialize: params => {
                return qs.stringify(params, {
                    arrayFormat: 'repeat'
                })
            }
        }
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as FetchProductSearchPageResponse;
            })
            .catch((error: any) => {

                if (error.response && (error.response.status === 403)) {
                    return(message.error("You do not have enough permissions. Please contact your Admin", 2));
                }

                this.handleError(error);
                throw error
            })
    }

    handleError(error: any) {
        console.error('ProductSearch:', error)
    }
}

export default new ProductSearchService()
