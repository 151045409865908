import {all, call, put, takeLatest} from 'redux-saga/effects'

import {
  FETCH_PRODUCT_SEARCH,
  FetchProductSearchAction
} from './types'
import {
  fetchProductSearchFailed,
  fetchProductSearchSucceeded
} from './actions'

import productSearchService from '../../services/ProductSearchService'
import {message} from "antd";
import * as React from "react";

export function* productSearchSaga() {
  yield all([
    watchFetchProductSearch(),
  ])
}

function* watchFetchProductSearch() {
  yield takeLatest(FETCH_PRODUCT_SEARCH, handleFetchKindOfItems)
}

function* handleFetchKindOfItems(action: FetchProductSearchAction) {
  try {
    const data = yield call([productSearchService, productSearchService.getProductSearch], action.pageRequest);
    yield put(fetchProductSearchSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchProductSearchFailed(`${error.response.data.detail}`))
  }
}
