import * as React from 'react';
import {Table, Typography} from 'antd';
import {ColumnsType, FilterValue, TablePaginationConfig} from "antd/lib/table/interface";

class EnhancedTableProps {
    tableName: string;

    onPaginationChanged?: (page: number, size: number) => void;

    currentPage: number;
    pageSize: number;

    totalCount: number;
    countOnPage: number;

    data: any[];
    columns: ColumnsType;
}

export default function EnhancedTable(props: EnhancedTableProps) {
    const {data, tableName, onPaginationChanged, totalCount, columns, currentPage, pageSize} = props;

    const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any) => {
        onPaginationChanged(pagination.current === 0 ? 0 : pagination.current - 1, pagination.pageSize);
    };

    return (
        <Table
            title={() => <Typography.Title level={3}>{tableName}</Typography.Title>}
            columns={columns}
            pagination={{
                hideOnSinglePage: true,
                current: currentPage + 1,
                total: totalCount,
                pageSize: pageSize,
                defaultCurrent: 1,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "25", "50"]
            }}
            onChange={handleTableChange}
            dataSource={data}>
        </Table>
    );
}

EnhancedTable.defaultProps = {
    onPaginationChanged: () => {
    }
}
