import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import {UserSession} from "../../store/userSession/types";
import { PageHeader } from 'antd';
import { getUsers } from "../../store/user/actions";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import {GetUsersRequest, GetUsersResponse} from "../../store/user/types";
import UserListView from "../../components/UserList/UserListView";
import {AppState} from "../../store";
import {withTranslation} from "react-i18next";

interface Props extends RouteComponentProps {
  userSession: UserSession;
  getUsers: (getUsersRequest: GetUsersRequest) => GetUsersResponse;
  pageRequest: GetUsersRequest;
  pageResponse: GetUsersResponse;
  loading?: boolean;
  error?: string;
}

interface State {
}

class UsersPage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.handlePaginationChanged = this.handlePaginationChanged.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    this.props.getUsers(this.props.pageRequest)
  }

  public render() {

    // @ts-ignore
    const {t} = this.props;

    return (
      <div className="product-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={t("user.users.title")}
            subTitle={t("user.users.subtitle")}
          >
          </PageHeader>
          <UserListView
              pageRequest={this.props.pageRequest}
              pageResponse={this.props.pageResponse}
              onPaginationChanged={this.handlePaginationChanged}
              handleSearch={this.handleSearch}
              onRowClick={(username) => this.props.history.push("/app/user?u=" + username)}
              loading={this.props.loading}
              error={this.props.error}
          />
        </PageWrapper>
      </div>
    );
  }

  private handleSearch(data: any) {
    this.props.getUsers({
      ...this.props.pageRequest,
      page: 0,
      search: data.value
    })
  }

  private handlePaginationChanged(page: number, size: number) {
    this.props.getUsers({
      ...this.props.pageRequest,
      page,
      size
    })
  }
}

const mapStateToProps = (state: AppState) => ({
  pageResponse: state.user.getUsersResponse,
  pageRequest: state.user.getUsersRequest,
  userSession: state.userSession.userSession,
  loading: state.user.loading,
  error: state.user.error
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getUsers: (pageRequest: GetUsersRequest) => {
    dispatch(getUsers(pageRequest))
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default withTranslation()(enhance(UsersPage));
