import * as React from "react";
import {Button, Col, Row} from "antd";
import CountrySelect from "../LanguageTypeSelect/CountrySelect";
import { countriesList, Country } from "../../store/common/types";
import {UserResponse} from "../../store/user/types";
import UserService from "../../services/UserService";
import {useEffect, useState} from "react";

export interface Props {
    user: UserResponse;
}

export const EditUser: React.FunctionComponent<Props> = (props) => {

    const {user} = props

    const [country, setCountry] = useState(user.country)

    useEffect(() => {
        setCountry(user.country)
    }, [user.country])

    const onSubmit = () => {
        UserService.updateUser({
            email: user.email,
            country: country
        }).then(() => {
            window.location.reload()
        }).catch((e) => {
            alert(`${e.message}: Check your permissions or contact to administrator`)
        });
    }

    return (
        <React.Fragment>
            <Row gutter={24}>
                <Col span={3}>
                <CountrySelect
                    value={country}
                    id={"country-select"}
                    list={countriesList}
                    onChange={(newValue) => {
                        console.log("Country selected", newValue)
                        setCountry(newValue as Country)
                    }}/>
                </Col>
                <Col span={2}>
                    <Button type="primary" htmlType="submit" onClick={onSubmit}>
                        Update
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default EditUser;
