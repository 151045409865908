import * as React from "react";
import {Button, Dropdown, Menu, message} from 'antd';
import {useTranslation} from "react-i18next";

export default function LanguageIcon(props: React.HTMLAttributes<HTMLDivElement>) {

    const {t, i18n} = useTranslation();

    const handleLanguageChange = (e: any) => {
        i18n.changeLanguage(e.key);
    };

    const menu = (
        <Menu>
            <Menu.Item disabled={i18n.language == "de"} key="de" onClick={handleLanguageChange}>
                DE
            </Menu.Item>
            <Menu.Item disabled={i18n.language == "en"} key="en" onClick={handleLanguageChange}>
                EN
            </Menu.Item>
        </Menu>
    );

    return (
        <div {...props}>
            <Dropdown overlay={menu}>
                <a>{i18n && i18n.language && (i18n.language + "").toUpperCase()}</a>
            </Dropdown>
        </div>
    );
}
