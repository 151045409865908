import {Reducer} from "redux";
import {
  CLOSE_PRODUCT_SEARCH_ERROR_MESSAGE,
  FETCH_PRODUCT_SEARCH,
  FETCH_PRODUCT_SEARCH_FAILED,
  FETCH_PRODUCT_SEARCH_SUCCEEDED,
  ProductSearchActionTypes,
  ProductSearchState
} from "./types";
import {pageResponse} from "../common/types";

const initialState: ProductSearchState = {
  itemRequest: {
    ean: null,
    country: null,
    language: null
  },
  pageRequest: {
    page: 0,
    size: 10,
    ean: null,
    country: "DE",
    language: "de_DE"
  },
  pageResponse: {
    ...pageResponse(),
    content: []
  },

  ean: null,
  loading: false,
  error: null
};

export const productSearchReducer: Reducer<ProductSearchState, ProductSearchActionTypes> = (state: ProductSearchState = initialState, action: ProductSearchActionTypes): ProductSearchState => {
  switch (action.type) {

    case FETCH_PRODUCT_SEARCH: {
      return {
        ...state,
        pageRequest: {
          ...action.pageRequest
        },
        loading: true,
        error: null
      }
    }

    case FETCH_PRODUCT_SEARCH_SUCCEEDED: {
      return {
        ...state,
        pageResponse: action.payload,
        loading: false,
        error: null
      }
    }

    case FETCH_PRODUCT_SEARCH_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case CLOSE_PRODUCT_SEARCH_ERROR_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: null
      }
    }

    default:
      neverReached(action);
  }

  return state;
};

const neverReached = (never: never) => {
};
