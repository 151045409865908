import {
  FETCH_USER_SESSION,
  FETCH_USER_SESSION_FAILED, FETCH_USER_SESSION_SILENT,
  FETCH_USER_SESSION_SUCCEEDED,
  UserSession,
  UserSessionActionTypes
} from './types'

export function fetchUserSession(): UserSessionActionTypes {
  return {
    type: FETCH_USER_SESSION
  }
}

export function fetchUserSessionSucceeded(payload: UserSession): UserSessionActionTypes {
  return {
    type: FETCH_USER_SESSION_SUCCEEDED,
    payload
  }
}

export function fetchUserSessionSilent(): UserSessionActionTypes {
  return {
    type: FETCH_USER_SESSION_SILENT
  }
}

export function fetchUserSessionFailed(error: string): UserSessionActionTypes {
  return {
    type: FETCH_USER_SESSION_FAILED,
    error
  }
}
