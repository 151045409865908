import * as React from "react";
import CAPOfflineView from "../../components/CAPOffline/CAPOfflineView";
import PageWrapper from "../../components/CAPOffline/PageWrapper";

class CAPOffline extends React.Component {

    public render() {
        return (
            <div className="cap-offline-page">
                <PageWrapper>
                    <CAPOfflineView lightView={false}/>
                </PageWrapper>
            </div>
        );
    }
}

export default CAPOffline;
