import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import {UserSession} from "../../store/userSession/types";
import {PageHeader, Typography} from 'antd';
import {createUser} from "../../store/user/actions";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import {CreateUserRequest, UserResponse} from "../../store/user/types";
import {AppState} from "../../store";
import AddUser from "../../components/AddUser/AddUser";
import EnhancedTable from "../../components/controls/EnhancedTable/EnhancedTable";
import {withTranslation} from "react-i18next";

interface Props extends RouteComponentProps {
  createUser: (createUserRequest: CreateUserRequest) => void
  createUserResponse: UserResponse,
  username: string;
  userSession: UserSession;
  loading?: boolean;
  error?: string;
}

interface State {
  items: UserResponse[]
}

class AddUserPage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      items: []
    }

    this.onSubmit = this.onSubmit.bind(this)

  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props.createUserResponse?.username && this.props.createUserResponse?.username !== prevProps.createUserResponse?.username) {
      this.setState({items: [...this.state.items, this.props.createUserResponse]})
    }
  }

  public render() {

    // @ts-ignore
    const {t} = this.props;

    const columns = [
      {
        title: t("general.username"),
        dataIndex: 'username',
        render: (item: string, record: UserResponse) => {
          return <Typography.Link href={`/app/user?u=${record.email}`}>{item}</Typography.Link>
        }
      },
      {
        title: t("general.email"),
        dataIndex: 'email',
        render: (item: string) => {
          return <Typography.Text code>{item}</Typography.Text>
        }
      },
      {
        title: t("general.country"),
        dataIndex: 'country',
        render: (item: string) => {
          return (<a>{item}</a>)
        }
      }
    ];


    return (
      <div className="product-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={t("user.addUser.title")}
            subTitle={t("user.addUser.subtitle")}>
          </PageHeader>

        <AddUser
            error={this.props.error}
            loading={this.props.loading}
            onSubmit={this.onSubmit}/>

          <EnhancedTable tableName={t("user.addUser.list.title")}
                         columns={columns}
                         data={this.state.items}
                         totalCount={this.state.items.length}
                         countOnPage={this.state.items.length}
                         currentPage={0}
                         pageSize={this.state.items.length}/>
        </PageWrapper>
      </div>
    )
  }

  public onSubmit(data: CreateUserRequest) {
    this.props.createUser(data)
  }
}

const mapStateToProps = (state: AppState) => ({
  createUserRequest: state.user.createUserRequest,
  createUserResponse: state.user.createUserResponse,
  userSession: state.userSession.userSession,
  loading: state.user.loading,
  error: state.user.error
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createUser: (createUserRequest: CreateUserRequest) => {
    dispatch(createUser(createUserRequest))
  },
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)

export default withTranslation()(enhance(AddUserPage));
