import * as React from "react";
import "./UserListView.css"
import EnhancedTable  from "../controls/EnhancedTable/EnhancedTable";
import {Button, Form, Input, Spin, Typography} from 'antd';
import {GetUsersRequest, GetUsersResponse} from "../../store/user/types";
import {useTranslation} from "react-i18next";

export interface Props {
  pageRequest: GetUsersRequest;
  pageResponse: GetUsersResponse;

  handleSearch: (data: any) => void

  onPaginationChanged: (page: number, size: number) => void;
  onRowClick?: (id: number | string, newTab?: boolean) => void;

  onGenerateReport?: () => void;
  generateReportLoading?: boolean;

  loading: boolean;
  error: string;
}

export const UserListView: React.FunctionComponent<Props> = (props) => {

  const { pageRequest, pageResponse, onPaginationChanged, handleSearch, loading, onRowClick } = props;

  const {t} = useTranslation();

  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 8 },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 8, offset: 2 },
  };

  const columns = [
    {
      title: t("general.email"),
      dataIndex: 'email',
      render: (item: string) => {
        return (<Typography.Link onClick={() => onRowClick(item)}>{item}</Typography.Link>)
      }
    },
    {
      title: t("general.username"),
      dataIndex: 'username',
      render: (item: string) => {
        return (<Typography>{item}</Typography>)
      }
    },
    {
      title: t("general.country"),
      dataIndex: 'country',
      render: (item: string) => {
        return (<Typography>{item}</Typography>)
      }
    }
  ];

  return props.error ?
    (
      <div className="view-error">{props.error}</div>
    )
    :
    (
      <React.Fragment>

        {
          loading && (<div>
            <div className="view-loading"/>
            <Spin className="view-loading-spinner"/>
          </div>)
        }

        <Form
            {...formItemLayout}
            layout={'horizontal'}
            form={form}
            onFinish={handleSearch}>
          <Form.Item name={"value"} label={t("general.filter")}>
            <Input placeholder={t("user.users.filter.placeholder")}/>
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button type="primary" htmlType="submit">{t("general.search")}</Button>
          </Form.Item>
        </Form>

        <EnhancedTable tableName={t("general.result")}
                       columns={columns}
                       data={pageResponse.users}
                       totalCount={pageResponse.total}
                       countOnPage={pageResponse.users.length}
                       currentPage={pageRequest.page}
                       pageSize={pageRequest.size}
                       onPaginationChanged={onPaginationChanged}/>
      </React.Fragment>
    );
};

export default UserListView;
