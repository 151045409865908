import {Country} from "../common/types";

export const GET_USERS = 'GET_USERS'
export const GET_USERS_SUCCEEDED = 'GET_USERS_SUCCEEDED'
export const GET_USERS_FAILED = 'GET_USERS_FAILED'

export const GET_USER = 'GET_USER'
export const GET_USER_SUCCEEDED = 'GET_USER_SUCCEEDED'
export const GET_USER_FAILED = 'GET_USER_FAILED'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCEEDED = 'CREATE_USER_SUCCEEDED'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'

export interface CreateUserRequest {
  email: string;
  username: string;
  country: Country;
}

export interface GetUsersRequest {
  page: number;
  size: number;
  search: string;
}

export interface GetUsersResponse {
  users: UserResponse[];
  total: number;
}

export interface GetUserRequest {
  email: string;
}

export interface UserResponse {
  username: string;
  email: string;
  country: Country;
  createdAt: string;
  updatedAt: string;
  permissions: Permission[];
}

export interface Permission {
  name: string;
  description: string;
}

export interface UpdateUserRequest {
  email: string;
  country: Country;
}

export interface GetUsersAction {
  type: typeof GET_USERS
  getUsersRequest: GetUsersRequest
}

export interface GetUsersSucceededAction {
  type: typeof GET_USERS_SUCCEEDED
  getUsersResponse: GetUsersResponse
}

export interface GetUsersFailedAction {
  type: typeof GET_USERS_FAILED
  error: string
}

export interface CreateUserAction {
  type: typeof CREATE_USER
  createUserRequest: CreateUserRequest
}

export interface CreateUserSucceededAction {
  type: typeof CREATE_USER_SUCCEEDED
  createUserResponse: UserResponse
}

export interface CreateUserFailedAction {
  type: typeof CREATE_USER_FAILED
  error: string
}

export interface GetUserAction {
  type: typeof GET_USER
  getUserRequest: GetUserRequest
}

export interface GetUserSucceededAction {
  type: typeof GET_USER_SUCCEEDED
  getUserResponse: UserResponse
}

export interface GetUserFailedAction {
  type: typeof GET_USER_FAILED
  error: string
}

export interface UserState {
  createUserRequest: CreateUserRequest;
  createUserResponse: UserResponse;
  getUsersRequest: GetUsersRequest;
  getUsersResponse: GetUsersResponse;
  getUserRequest: GetUserRequest;
  getUserResponse: UserResponse;
  payload: void;
  loading: boolean;
  error: string;
}

export type UserActionTypes = GetUsersAction | GetUsersSucceededAction | GetUsersFailedAction |
    GetUserAction | GetUserSucceededAction | GetUserFailedAction |
    CreateUserAction | CreateUserSucceededAction | CreateUserFailedAction;

