import * as React from "react";
import "./Login.css";
import {resolveApiBaseUrl} from "../../environment";
import * as qs from 'qs';
import {Alert, Button, Popover} from 'antd';
import {QuestionCircleTwoTone} from "@ant-design/icons";

const redirectToLogin = () => {
    window.location.href = window.location.href = resolveApiBaseUrl() + '/gateway/login';
};

export default function Login() {

    const queryString = window.location.search;
    const {error_login} = qs.parse(queryString, {ignoreQueryPrefix: true});

    const loginContent = (
        <div>
            <p>Please create a <a href={"https://mms.cherwellondemand.com/"} target="_blank">Cherwell</a> ticket in case
                your login does not work anymore.</p>
            <p>Please provide your country and login within the ticket.</p>
            <p>Please assign the ticket to MMST Product Core.</p>
        </div>
    );

    const offlineAppContent = (
        <div>
            <p>You can create products manually from here.</p>
        </div>
    );

    return (
        <div className="login__page">
            {error_login && (
                <Alert message="Error" description={error_login} type="error" closable className="login__page__error"
                       onClose={() => {
                       }}/>)}
            <div className="login__block">
                <div className="login__block__caption">MMS Create a Product</div>
                <Button className="login__block__button" type="primary" onClick={redirectToLogin}>
                    Login with FIFA
                </Button>
                <span>         </span>
                <Popover content={loginContent} title={"Can't sign in?"}>
                    <QuestionCircleTwoTone/>
                </Popover>
                <p> </p>
                <Popover content={offlineAppContent} title={"Don't have Login?"} placement="bottom">
                    <div>
                        <a href={"/app/cap-offline"}>Offline CAP Application</a>
                    </div>
                </Popover>
            </div>
        </div>
    );
}
