import axiosInstance from '../axiosInstance'
import { AxiosInstance } from "axios";
import { resolveApiBaseUrl } from "../environment";
import * as qs from "qs";
import {PriceCode, PriceCodeGetRequest, PriceCodeSearchRequest, PriceCodeSearchResponse} from "../store/price/type";

class PriceService {
  axios: AxiosInstance;

  constructor () {
    this.axios = axiosInstance;
  }

  getPriceCode(priceCodeGetRequest: PriceCodeGetRequest): Promise<PriceCode> {

    const params = {
      supplierId: priceCodeGetRequest.supplierId,
      manufacturerId: priceCodeGetRequest.manufacturerId
    } as any;

    return this.axios.get(`/gateway/mdng-getter-api/price-code/${priceCodeGetRequest.id}`, {
        baseURL: resolveApiBaseUrl(),
        params: params,
        paramsSerializer: {
            serialize: params => {
            return qs.stringify(params, {
                arrayFormat: 'repeat'
            })
        }
    }
    })
        .then((response: any) => {
          return {
            ...response['data']
          } as PriceCode;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  searchPriceCodes(priceCodeSearchRequest: PriceCodeSearchRequest): Promise<PriceCodeSearchResponse> {

    const params = {
      offset: priceCodeSearchRequest.offset,
      limit: priceCodeSearchRequest.limit,
      value: priceCodeSearchRequest.search,
      supplierId: priceCodeSearchRequest.supplierId,
      manufacturerId: priceCodeSearchRequest.manufacturerId
    } as any;

    return this.axios.get(`/gateway/mdng-getter-api/price-code`, {
        baseURL: resolveApiBaseUrl(),
        params: params,
        paramsSerializer: {
            serialize: params => {
            return qs.stringify(params, {
                arrayFormat: 'repeat'
            })
        }
    }
    })
      .then((response: any) => {
        return {
          ...response['data']
        } as PriceCodeSearchResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  handleError(error: any) {
    console.error('PriceService:', error)
  }
}

export default new PriceService()
