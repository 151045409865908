import * as React from "react";
import { useState } from "react";
import {Alert, Button, Checkbox, Collapse, DatePicker, Divider, Form, Input, InputNumber, Radio, Spin} from 'antd';
import {Product, ProductType} from "../../store/product/types";
import ProductGroupLoadableSelect from "../controls/ProductGroupLoadableSelect";
import { ManufacturerLoadableSelect } from "../controls/ManufacturerLoadableSelect";
import TaxLoadableSelect from "../controls/TaxLoadableSelect";
import SupplierLoadableSelect from "../controls/SupplierLoadableSelect";
import { countriesList, Country } from "../../store/common/types";
import PriceCodeSelect from "../controls/PriceCodeSelect";
import moment from 'moment';
import MediumLoadableSelect from "../controls/MediumLoadableSelect";
import AgeRestrictionSelect from "../controls/AgeRestrictionSelect";
import { UserSession } from "../../store/userSession/types";
import CountrySelect from "../LanguageTypeSelect/CountrySelect";
import CAPOfflineView from "../CAPOffline/CAPOfflineView";
import {useTranslation} from "react-i18next";

const { TextArea } = Input;
const { Panel } = Collapse;

const PRODUCT_NAME_MAX_LENGTH = 50;

export interface Props {
  userSession: UserSession;
  product: Product;
  editMode: boolean;
  editModeCountry?: string;
  onProductCreate: (product: Product) => void;
  onProductUpdate: (product: Product) => void;
  loading: boolean;
  error: string;
}

export const ProductDetailsView: React.FunctionComponent<Props> = (props) => {

  const { product, editMode, editModeCountry, onProductCreate, onProductUpdate, userSession, loading, error } = props;

  const {t} = useTranslation();

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 10 },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 10, offset: 3 },
  };

  const [productType, setProductType] = useState(product ? product.productType : "UE");
  const [manufacturerId, setManufacturerId] = useState(product ? product.manufacturerId : null);
  const [priceCodeId, setPriceCodeId] = useState( product?.ttProductExtension ? product.ttProductExtension.priceCodeId : null);
  const [priceCodeName, setPriceCodeName] = useState( product?.ttProductExtension ? product.ttProductExtension.priceCode : null);
  const [supplierId, setSupplierId] = useState(product ? product.supplierId : null);
  const [country, setCountry] = useState(product ? product.country : "DE");

  const allowedCountries = userSession.permissions.indexOf("Admin") > -1 ? [...countriesList] : [userSession.country];
  const countryNotAllowed = editMode && allowedCountries.indexOf(editModeCountry) < 0;

  let form: any;
  if (!countryNotAllowed) {
    [form] = Form.useForm();
  }

  // initiate form fields
  form.setFieldsValue(
      {
        "country": "DE"
      }
  );

  React.useEffect(() => {

    if (countryNotAllowed) {
      return;
    }

    if (product && product.productId) {

      setCountry(product.country);
      setProductType(product.productType);
      setManufacturerId(product.manufacturerId);
      setSupplierId(product.supplierId);

      form.setFieldsValue({
        "productType": product.productType,
        "productId": product.productId,
        "country": product.country,
        "gtin": product.gtin,
        "name": product.name,
        "productGroupId": product.productGroupId,
        "manufacturerId": product.manufacturerId,
        "supplierId": product.supplierId,
        "supplierOrderNumber": product.supplierOrderNumber,
        "taxKey": product.taxKey,
        "packingContent": product.packingContent,
        "packingUnit": product.packingUnit,
        "info": product.info,
        "ueProductExtension": product.ueProductExtension,
        "ttProductExtension": {
          ... product.ttProductExtension,
          releaseDate: product.ttProductExtension && product.ttProductExtension.releaseDate != null ? moment(product.ttProductExtension.releaseDate) : null,
          cancelDate: product.ttProductExtension && product.ttProductExtension.cancelDate != null ? moment(product.ttProductExtension.cancelDate) : null,
        }
      })
    } else {
      setProductType("UE");

      form.setFieldsValue({
        "productType": "UE",
        "productId": null,
        "country": null
      })
    }

  }, [(product && product.productId) ? product.productId : 0]);

  const onCountryChange = (value: Country | string) => {

    setCountry(value as Country);

    setManufacturerId(undefined);
    setSupplierId(undefined);

    form.setFieldsValue({
      "productGroupId": undefined,
      "manufacturerId": undefined,
      "supplierId": undefined,
      "taxKey": undefined,
    });

    resetPriceCode();
    resetMedium();
  };

  const resetProductTypeRelatedFields = () => {
    form.setFieldsValue({
      "productGroupId": undefined,
      "supplierId": undefined
    });
  }

  const onPriceChange = (priceCodeId: number, priceCodeName: string) => {
    setPriceCodeId(priceCodeId)
    setPriceCodeName(priceCodeName)
  }

  const onManufacturerChange = (value: number) => {

    setManufacturerId(value);
    setSupplierId(undefined);

    form.setFieldsValue({
      "supplierId": undefined
    });

    resetPriceCode();
  };

  const onSupplierChange = (value: number) => {

    setSupplierId(value);

    resetPriceCode();
  };

  const resetPriceCode = () => {

    if (form.getFieldValue("productType") === "TT") {

      form.setFieldsValue({
        "ttProductExtension": {
          ... form.getFieldValue("ttProductExtension"),
          "priceCode": undefined
        },
      });
    }
  };

  const resetMedium = () => {

    if (form.getFieldValue("productType") === "TT") {

      form.setFieldsValue({
        "ttProductExtension": {
          ... form.getFieldValue("ttProductExtension"),
          "medium": undefined
        },
      });
    }
  };

  const onSubmit = () => {

    const productType: ProductType = form.getFieldValue("productType");
    const productId = form.getFieldValue("productId");
    const country: Country = form.getFieldValue("country");
    const gtin = form.getFieldValue("gtin");
    const name = form.getFieldValue("name");
    const productGroupId = form.getFieldValue("productGroupId");
    const manufacturerId = form.getFieldValue("manufacturerId");
    const supplierId = form.getFieldValue("supplierId");
    const supplierOrderNumber = form.getFieldValue("supplierOrderNumber");
    const taxKey = form.getFieldValue("taxKey");
    const packingContent = form.getFieldValue("packingContent");
    const packingUnit = form.getFieldValue("packingUnit");
    const info = form.getFieldValue("info");
    const ueProductExtension = form.getFieldValue("ueProductExtension");
    const ttProductExtension = form.getFieldValue("ttProductExtension");
    if (ttProductExtension) {
      ttProductExtension.priceCodeId = priceCodeId;
      ttProductExtension.priceCode = priceCodeName;
    }

    const createOrUpdateProduct: Product = {
      productType,
      productId,
      country,
      gtin,
      name,
      productGroupId,
      manufacturerId,
      supplierId,
      supplierOrderNumber,
      taxKey,
      packingContent,
      packingUnit,
      info,
      ueProductExtension,
      ttProductExtension
    };

    if (product && product.productId) {
      onProductUpdate({
        ...createOrUpdateProduct
      });
    } else {
      onProductCreate({
        ...createOrUpdateProduct,
        productId: null
      });
    }
  };

  let releaseDate: any;

  function releaseDateChange(date: any, dateString: any) {
    resetCancelDate();
    releaseDate = dateString;
  }

  function disabledDate(current: any) {
    return current && current <= moment(releaseDate, 'DD-MM-YYYY').endOf('day');
  }

  const resetCancelDate = () => {

    if (form.getFieldValue("productType") === "TT") {

      form.setFieldsValue({
        "ttProductExtension": {
          ... form.getFieldValue("ttProductExtension"),
          "cancelDate": undefined
        },
      });
    }
  };

  return <React.Fragment>

    {
      loading && (<div>
        <div className="view-loading"/>
        <Spin className="view-loading-spinner" size={"large"}/>
      </div>)
    }

    {
      (error || countryNotAllowed) && (<React.Fragment>
        <Alert
          message="Error"
          description={error || "Action is not allowed. Please contact administrator."}
          closable={!!error}
          type="error"
        />
        <br/>
      </React.Fragment>)
    }

    {
      !countryNotAllowed &&
      <React.Fragment>

        <Collapse>
          <Panel header={<Alert message= {t("general.note")} type="info" showIcon description={t("product.template.note")}/>} key="1">
            <CAPOfflineView lightView={true}/>
          </Panel>
        </Collapse>

          <React.Fragment><br/><Divider orientation={"left"}>
            {product && product.productId ? <span>{t("product.request.update")}</span> : <span>{t("product.request.create")}</span>}
          </Divider><br/></React.Fragment>

          <Form
            {...formItemLayout}
            layout={'horizontal'}
            form={form}
            onFinish={onSubmit}
          >

              <Form.Item label={t("product.type")}
                         name={"productType"}
                         rules={[{ required: true, message: t("product.type.placeholder") }]}>
                  <Radio.Group
                      disabled= {true}
                      options={[{ label: 'UE', value: 'UE' }, { label: 'TT', value: 'TT' }]}
                      onChange={(value: any) => {
                        setProductType(value.target.value)
                        resetProductTypeRelatedFields();
                      }}
                  />
              </Form.Item>

              <Form.Item label={t("product.country")}
                         name={"country"}
                         rules={[{ required: true, message: t("product.country.placeholder") }]}>
                  <CountrySelect placeholder={t("product.county.placeholder")} id={"country-select"} list={allowedCountries} onChange={onCountryChange}
                                 disabled={true} />
              </Form.Item>

              <Form.Item label="EAN"
                         name={"gtin"}
                         rules={[{ required: true, pattern: new  RegExp("^[0-9]{8,13}$"),
                           message: t("product.ean.validation")}]}>
                  <Input
                      placeholder={t("product.ean.placeholder")}
                  />
              </Form.Item>

              <Form.Item label= {t("product.name")}
                         name={"name"}
                         rules={[{ required: true, message: t("product.name.validation") },
                           {max: PRODUCT_NAME_MAX_LENGTH, message: t("product.name.length.validation", {'maxLength': PRODUCT_NAME_MAX_LENGTH})}]}>
                  <Input
                      placeholder= {t("product.name.placeholder")}
                  />
              </Form.Item>

              <Form.Item label= {t("product.group")}
                         name={"productGroupId"}
                         rules={[{ required: true, message: t("product.group.validation") }]}>

                  <ProductGroupLoadableSelect placeholder={t("product.group.validation")} country={country} productType={productType}/>
              </Form.Item>

              <Form.Item label= {t("product.manufacturer")}
                         name={"manufacturerId"}
                         rules={[{ required: true, message: t("product.manufacturer.validation") }]}>

                  <ManufacturerLoadableSelect placeholder={t("product.manufacturer.validation")} country={country} onChange={onManufacturerChange}/>
              </Form.Item>

              <Form.Item label= {t("product.supplier")}
                         name={"supplierId"}
                         rules={[{ required: true, message: t("product.supplier.validation") }]}>

                  <SupplierLoadableSelect placeholder={t("product.supplier.validation")} manufacturerId={manufacturerId} country={country} productType={productType}
                                          onChange={onSupplierChange} />
              </Form.Item>

              <Divider/>
              <Form.Item label={t("product.packing.content")}
                         name={"packingContent"}
                         rules={[{ required: false, message: t("product.packing.content.validation"),
                           pattern: new RegExp("^[1-9][0-9]*$") }]}>
                  <Input defaultValue={1} placeholder= {t("product.packing.content.placeholder")}/>
              </Form.Item>

              <Form.Item label={t("product.packing.unit")} name={"packingUnit"}
                         rules={[{ required: false, message: t("product.packing.unit.validation"),
                           pattern: new RegExp("^[1-9][0-9]*$")}]}>
                  <Input defaultValue={1} placeholder= {t("product.packing.unit.placeholder")}/>
              </Form.Item>

              <Divider/>
              <Form.Item label= {t("product.orderNumber")}
                         name={"supplierOrderNumber"}
                         rules={[{ required: false, message: t("product.orderNumber.validation") }]}>
                  <Input
                      placeholder= {t("product.orderNumber.placeholder")}
                  />
              </Form.Item>

            {
              (productType === "UE") &&

              <Form.Item label= {t("product.manufacturer.orderNumber")}
                         name={["ueProductExtension", "manufacturerOrderNumber"]}
                         rules={[{ required: false, message: t("product.manufacturer.orderNumber.validation") }]}>
                  <Input
                      placeholder= {t("product.manufacturer.orderNumber.placeholder")}
                  />
              </Form.Item>
            }

              <Divider/>
            {
              (productType === "TT") &&
              <React.Fragment>
                  <Form.Item label={t("product.label")}
                             name={["ttProductExtension", "label"]}
                             rules={[{ required: false }]}>
                      <Input
                          placeholder= {t("product.label.placeholder")}
                      />
                  </Form.Item>

                  <Form.Item label={t("product.deleteFlag")}
                             name={["ttProductExtension", "deleteFlag"]}
                             valuePropName="checked"
                             rules={[{ required: false }]}>
                      <Checkbox/>
                  </Form.Item>

                  <Form.Item label= {t("product.medium")}
                             name={["ttProductExtension", "medium"]}
                             rules={[{ required: true, message: t("product.medium.validation") }]}>

                      <MediumLoadableSelect country={country}/>
                  </Form.Item>

                  <Form.Item label="FSK / USK"
                             name={["ttProductExtension", "fskUsk"]}
                             rules={[{ required: false, message: t("product.FSKUSK.validation") }]}>

                      <AgeRestrictionSelect id={"fskUsk"}/>
                  </Form.Item>

                  <Form.Item label= {t("product.releaseDate")}
                             name={["ttProductExtension", "releaseDate"]}
                             rules={[{ required: true, message: t("product.releaseDate.validation")}]}
                  >
                      <DatePicker mode="date" format="DD-MM-YYYY" onChange={releaseDateChange}/>
                  </Form.Item>

                  <Form.Item label= {t("product.cancelDate")}
                             name={["ttProductExtension", "cancelDate"]}
                             rules={[{ required: true, message: t("product.cancelDate.validation") }]}
                  >
                      <DatePicker mode="date" format="DD-MM-YYYY" disabledDate={disabledDate}/>
                  </Form.Item>

                  <Form.Item label= {t("product.priceCode")}
                             name={["ttProductExtension", "priceCode"]}
                             rules={[{ required: true, message: t("product.priceCode.validation") }]}>

                      <PriceCodeSelect priceCodeId={priceCodeId}
                                       manufacturerId={manufacturerId}
                                       supplierId={supplierId}
                                       onChange={onPriceChange}/>
                  </Form.Item>

                  <Form.Item label={t("product.artist")}
                             name={["ttProductExtension", "artist"]}
                             rules={[{ required: true, message: t("product.artist.validation") }]}>
                      <Input
                          placeholder= {t("product.artist.placeholder")}
                      />
                  </Form.Item>

                  <Form.Item label={t("product.composer")}
                             name={["ttProductExtension", "composer"]}
                             rules={[{ required: true, message: t("product.composer.validation") }]}>
                      <Input
                          placeholder= {t("product.composer.placeholder")}
                      />
                  </Form.Item>

                  <Divider/>
              </React.Fragment>
            }

              <Form.Item label= {t("product.taxKey")}
                         name={"taxKey"}
                         rules={[{ required: true, message: t("product.taxKey.validation") }]}>

                  <TaxLoadableSelect placeholder={t("product.taxKey.validation")} country={country}/>
              </Form.Item>

            {
              (productType === "UE") &&
              <React.Fragment>

                <Alert message= {t("general.warning")} description={t("product.priceFormat.validation")}
                       type="warning" showIcon closable/>

                <span>&nbsp; &nbsp;</span>

                  <Form.Item label= {t("product.purchasePrice")}
                             name={["ueProductExtension", "purchasePrice"]}
                             rules={[{ required: true, pattern: new RegExp("^\\d{0,5}(\\.\\d{1,2})?$"),
                               message: t("product.purchasePrice.validation") }]}>
                      <InputNumber placeholder="9.99" style={{ width: '100%' }}/>
                  </Form.Item>

                  <Form.Item label= {t("product.salesPrice")}
                             name={["ueProductExtension", "salesPrice"]}
                             rules={[{ required: true, pattern: new RegExp("^\\d{0,5}(\\.\\d{1,2})?$"),
                               message: t("product.salesPrice.validation") }]}>
                      <InputNumber placeholder="19.99" style={{ width: '100%' }}/>
                  </Form.Item>
              </React.Fragment>
            }

              <Divider/>
              <Form.Item label= {t("product.information")}
                         name={"info"}
                         rules={[{ required: false, message: t("product.information.validation") }]}>
                  <TextArea
                      rows={4}
                      placeholder= {t("product.information.placeholder")}
                  />
              </Form.Item>

              <Form.Item {...buttonItemLayout}>
                  <Button type="primary" htmlType="submit">
                    {product && product.productId ? t("product.submit.update") : t("product.submit.create")}
                  </Button>
              </Form.Item>
          </Form>
      </React.Fragment>
    }

  </React.Fragment>
};
