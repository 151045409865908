import axios, { AxiosInstance } from 'axios'

const options = {
  headers: {
    'accept': 'application/json'
  },
  withCredentials: true
};

const axiosInstance: AxiosInstance = axios.create(options)

export default axiosInstance
