import axiosInstance from '../axiosInstance'
import { AxiosInstance } from "axios";
import { UserSession } from "../store/userSession/types";
import { resolveApiBaseUrl } from "../environment";
import {
  CreateUserRequest,
  GetUserRequest,
  GetUsersRequest,
  GetUsersResponse,
  UpdateUserRequest,
  UserResponse
} from "../store/user/types";
import * as qs from "qs";

class UserService {
  axios: AxiosInstance;

  constructor () {
    this.axios = axiosInstance;
  }

  getUsers(pageRequest: GetUsersRequest): Promise<GetUsersResponse> {

    const params = {
      page: pageRequest.page,
      size: pageRequest.size,
      search: pageRequest.search
    } as any;

    return this.axios.get(`/user`, {
      baseURL: resolveApiBaseUrl(),
      params: params,
      paramsSerializer: {
        serialize: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    }
    })
        .then((response: any) => {
          return {
            ...response['data']
          } as GetUsersResponse;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  getUser(getUserRequest: GetUserRequest): Promise<UserResponse> {

    return this.axios.get(`/user/${encodeURIComponent(getUserRequest.email)}`, {
        baseURL: resolveApiBaseUrl(),
        paramsSerializer: {
            serialize: params => {
            return qs.stringify(params, {
                arrayFormat: 'repeat'
            })
        }
    }
    })
        .then((response: any) => {
          return {
            ...response['data']
          } as UserResponse;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  updateUser(updateUserRequest: UpdateUserRequest): Promise<void> {

    return this.axios.put(`/user`, updateUserRequest, {
        baseURL: resolveApiBaseUrl(),
        paramsSerializer: {
            serialize: params => {
            return qs.stringify(params, {
                arrayFormat: 'repeat'
            })
        }
    }
    })
        .then((response: any) => {
          return {
            ...response['data']
          } as void;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  createUser(createUserRequest: CreateUserRequest): Promise<UserResponse> {

    return this.axios.post(`/user`, createUserRequest, {
        baseURL: resolveApiBaseUrl(),
        paramsSerializer: {
            serialize: params => {
            return qs.stringify(params, {
                arrayFormat: 'repeat'
            })
        }
    }
    })
        .then((response: any) => {
          return {
            ...response['data']
          } as UserResponse;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  deleteUser(username: string): Promise<void> {

    return this.axios.delete(`/user/${username}`, {
        baseURL: resolveApiBaseUrl(),
        paramsSerializer: {
            serialize: params => {
            return qs.stringify(params, {
                arrayFormat: 'repeat'
            })
        }
    }
    })
        .then((response: any) => {
          return {
            ...response['data']
          } as void;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  deletePermission(username: string, permission: string): Promise<void> {

    return this.axios.delete(`/user-permission/user/${username}/permission/${permission}`, {
        baseURL: resolveApiBaseUrl(),
        paramsSerializer: {
            serialize: params => {
            return qs.stringify(params, {
                arrayFormat: 'repeat'
            })
        }
    }
    })
        .then((response: any) => {
          return {
            ...response['data']
          } as void;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  getUserSession(): Promise<UserSession> {
    return this.axios.get('/session', {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return {
          loggedIn: true,
          firstname: response['data'].firstname,
          lastname: response['data'].lastname,
          email: response['data'].email,
          user: response['data'].user,
          expiresIn: response['data'].expiresIn,
          permissions: response['data'].permissions,
          country: response['data'].country,
        }
      })
      .catch((error: any) => {
        if (error.response && error.response.status === 403) {
          return {
            loggedIn: false,
            firstname: null,
            lastname: null,
            email: null,
            user: null,
            expiresIn: 0,
            permissions: [],
            country: undefined,
          }
        } else {
          this.handleError(error);
          throw error;
        }
      })
  }

  handleError (error: any) {
    console.error('UserService:', error)
  }
}

export default new UserService()
