import {
  FETCH_PRODUCT_SEARCH,
  FETCH_PRODUCT_SEARCH_SUCCEEDED,
  FETCH_PRODUCT_SEARCH_FAILED, ProductSearchActionTypes, FetchProductSearchItemsPageRequest, FetchProductSearchPageResponse
} from './types'

export function fetchProductSearch(pageRequest: FetchProductSearchItemsPageRequest): ProductSearchActionTypes {
  return {
    type: FETCH_PRODUCT_SEARCH,
    pageRequest
  }
}

export function fetchProductSearchSucceeded(payload: FetchProductSearchPageResponse): ProductSearchActionTypes {
  return {
    type: FETCH_PRODUCT_SEARCH_SUCCEEDED,
    payload
  }
}

export function fetchProductSearchFailed(error: string): ProductSearchActionTypes {
  return {
    type: FETCH_PRODUCT_SEARCH_FAILED,
    error
  }
}