import { Country } from "../common/types";

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_SUCCEEDED = 'CREATE_PRODUCT_SUCCEEDED';
export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED';

export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_PRODUCT_SUCCEEDED = 'FETCH_PRODUCT_SUCCEEDED';
export const FETCH_PRODUCT_FAILED = 'FETCH_PRODUCT_FAILED';

export type ProductType = 'UE' | 'TT';
export const productTypesList = ['UE', 'TT'];

export interface ProductState {
  newProduct: Product;
  existingProduct: Product;
  refId: string;
  loading: boolean;
  error: string;
}

export interface UEProductExtension {
  purchasePrice: number;
  salesPrice: number;
  manufacturerOrderNumber: string
}

export interface TTProductExtension {
  label: string;
  deleteFlag: boolean;
  priceCodeId: number;
  priceCode: string;
  medium: string;
  fskUsk: string;
  releaseDate: string;
  cancelDate: string;
  artist: string;
  composer: string;
}

export interface Product {
  productType: ProductType;
  productId: number;
  country: Country;
  gtin: string;
  name: string;
  productGroupId: number;
  manufacturerId: number;
  supplierId: number;
  supplierOrderNumber: string;
  packingContent: number;
  packingUnit: number;
  taxKey: number;
  info: string;
  ueProductExtension: UEProductExtension;
  ttProductExtension: TTProductExtension;
}

export interface CreateProductRequest {
  product: Product;
}

export interface CreateProductResponse {
  refId: string;
}

export interface FetchProductRequest {
  productId: number;
  country: string;
}

export interface FetchProductResponse {
  product: Product;
}

export interface CreateProductAction {
  type: typeof CREATE_PRODUCT,
  payload: CreateProductRequest
}

export interface CreateProductSucceededAction {
  type: typeof CREATE_PRODUCT_SUCCEEDED
  payload: CreateProductResponse
}

export interface CreateProductFailedAction {
  type: typeof CREATE_PRODUCT_FAILED
  error: string
}

export interface FetchProductAction {
  type: typeof FETCH_PRODUCT,
  payload: FetchProductRequest
}

export interface FetchProductSucceededAction {
  type: typeof FETCH_PRODUCT_SUCCEEDED
  payload: FetchProductResponse
}

export interface FetchProductFailedAction {
  type: typeof FETCH_PRODUCT_FAILED
  error: string
}

export type ProductActionTypes = CreateProductAction | CreateProductSucceededAction | CreateProductFailedAction
  | FetchProductAction | FetchProductSucceededAction | FetchProductFailedAction;
