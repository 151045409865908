import { Select } from "antd";
import * as React from "react";
import {Country} from "../../store/common/types";

const { Option } = Select;

export interface Props {
  id: string
  value?: string;
  list: string[];
  mode?: 'multiple' | 'tags';
  onChange?: (value: Country | string) => void;
  disabled?: boolean;
  placeholder?: string;
}

export default function CountrySelect(props: Props) {

  const { id, mode, value, onChange, list, disabled, placeholder = "Select country"} = props;

  return (
    <Select id={id}
            style={{width: "100%"}}
            mode={mode}
            disabled={disabled}
            value={value}
            placeholder={placeholder}
            onChange={(value) => {
              onChange(value as Country)
            }}>
      {
        list.map(country => {
          return <Option value={country}>{country}</Option>
        })
      }
    </Select>
  )
}
