import * as React from "react";
import {Alert, Button, Form, Input, Spin} from 'antd';
import {CreateUserRequest} from "../../store/user/types";
import CountrySelect from "../LanguageTypeSelect/CountrySelect";
import {countriesList} from "../../store/common/types";
import {useTranslation} from "react-i18next";

export interface Props {
    onSubmit: (createUserRequest: CreateUserRequest) => void;
    loading: boolean;
    error: string;
}

export const AddUser: React.FunctionComponent<Props> = (props) => {

    const {onSubmit, loading, error} = props;

    const {t} = useTranslation();

    const [form] = Form.useForm();

    const formItemLayout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 10 },
    };

    const buttonItemLayout = {
        wrapperCol: { span: 10, offset: 3 },
    };

    return <React.Fragment>

        {
            loading && (<div>
                <div className="view-loading"/>
                <Spin className="view-loading-spinner"/>
            </div>)
        }

        {
            error && (<React.Fragment>
                <Alert
                    message="Error"
                    description={error}
                    type="error"
                />
                <br/>
            </React.Fragment>)
        }

        <Form {...formItemLayout}
            layout={'horizontal'}
            form={form}
            onFinish={(data :any) => onSubmit(data as CreateUserRequest)}>
            <Form.Item name={"username"}
                       label={t("general.username") + ":"}
                       tooltip={t("user.addUser.username.tooltip")}
                       rules={[{ required: true, message: t("user.addUser.username.validation") }]}>
                <Input placeholder= {t("user.addUser.username.placeholder")}/>
            </Form.Item>
            <Form.Item name={"email"}
                       label={t("general.email") + ":"}
                       tooltip={t("user.addUser.email.tooltip")}
                       rules={[{ required: true, type: "email", message: t("user.addUser.email.validation") }]}>
                <Input placeholder={t("user.addUser.email.placeholder")}/>
            </Form.Item>
            <Form.Item name={"country"}
                       label={t("general.country") + ":"}
                       tooltip={t("user.addUser.country.tooltip")}
                       rules={[{ required: true, message: t("user.addUser.country.validation") }]}>
                <CountrySelect id={"country-select"} placeholder={t("user.addUser.country.placeholder")} value={null} list={countriesList} onChange={() => {}}/>
            </Form.Item>
            <Form.Item {...buttonItemLayout}>
                <Button type="primary" htmlType="submit">{t("general.add")}</Button>
            </Form.Item>
        </Form>

    </React.Fragment>
};

export default AddUser;
