import axiosInstance from '../axiosInstance'
import { AxiosInstance } from "axios";
import { resolveApiBaseUrl } from "../environment";
import {
  CreateProductRequest,
  CreateProductResponse,
  FetchProductRequest,
  FetchProductResponse
} from "../store/product/types";
import { Country, mapCountryToLanguage } from "../store/common/types";
import {message} from "antd";

class ProductService {
  axios: AxiosInstance;

  constructor () {
    this.axios = axiosInstance;
  }

  createProduct(request: CreateProductRequest): Promise<CreateProductResponse> {

    return this.axios.post(`/product/`, request.product, {
        baseURL: resolveApiBaseUrl()
      },
    )
      .then((response: any) => {
        return {
          refId: response['data'].refId
        } as CreateProductResponse;
      })
      .catch((error: any) => {

        if (error.response && (error.response.status === 403)) {
          message.error("You do not have enough permissions. Please contact your Admin", 2);
        }

        this.handleError(error);
        throw error
      })
  }

  fetchProduct(itemRequest: FetchProductRequest): Promise<FetchProductResponse> {

    const language = mapCountryToLanguage(itemRequest.country as Country);
    return this.axios.get(`/product/${itemRequest.country}/${itemRequest.productId}?language=${language}`, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return {
          product: response['data']
        } as FetchProductResponse;
      })
      .catch((error: any) => {

        if (error.response && (error.response.status === 403)) {
          message.error("You do not have enough permissions. Please contact your Admin", 2);
        }

        this.handleError(error);
        throw error
      })
  }

  handleError(error: any) {
    console.error('ProductService:', error)
  }
}

export default new ProductService()
