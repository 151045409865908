
const resolveApiBaseUrl = () => {
  if (window.location.href.startsWith("http://localhost:7000")) {
    return 'http://localhost:8080';
  } else {
    return '';
  }
};

export { resolveApiBaseUrl };
