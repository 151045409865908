import axiosInstance from '../axiosInstance'
import { AxiosInstance } from "axios";
import { resolveApiBaseUrl } from "../environment";
import * as qs from "qs";
import {
  GetProductGroupRequest,
  GetProductGroupResponse,
  SearchProductGroupsPageRequest,
  SearchProductGroupsPageResponse
} from "../store/productGroups/types";

class ProductGroupService {
  axios: AxiosInstance;

  constructor () {
    this.axios = axiosInstance;
  }

  searchProductGroups(pageRequest: SearchProductGroupsPageRequest): Promise<SearchProductGroupsPageResponse> {

    const params = {
      page: pageRequest.page,
      size: pageRequest.size
    } as any;

    if (pageRequest.search) {
      params["search"] = pageRequest.search;
    }

    if (pageRequest.productType) {
      params["product_type"] = pageRequest.productType;
    }

    if (pageRequest.language) {
      params["language"] = pageRequest.language;
    }

    params.sort = "id,ASC";

    return this.axios.get(`/gateway/product-groups-api/cap/items`, {
      baseURL: resolveApiBaseUrl(),
      params: params,
      paramsSerializer: {
        serialize: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    }
    })
      .then((response: any) => {
        return {
          ...response['data']
        } as SearchProductGroupsPageResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  getProductGroup(itemRequest: GetProductGroupRequest): Promise<GetProductGroupResponse> {

    return this.axios.get(`/gateway/product-groups-api/cap/items/${itemRequest.id}?product_type=${itemRequest.productType}&language=${itemRequest.language}`, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return {
          item: response['data']
        } as GetProductGroupResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  handleError(error: any) {
    console.error('ProductGroupsService:', error)
  }
}

export default new ProductGroupService()
