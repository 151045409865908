export type Country = 'AT' | 'BE' | 'CH' | 'DE' | 'ES' | 'HU' | 'IT' | 'LU' | 'NL' | 'PL' | 'PT' | 'SE' | 'TR';

export const countriesList = ['AT', 'BE', 'CH', 'DE', 'ES', 'HU', 'IT', 'LU', 'NL', 'PL', 'PT', 'SE', 'TR'];

export type LanguageType = 'ca_ES' | 'de_AT' | 'de_CH' | 'de_DE' | 'de_LU' | 'el_GR' | 'en_GB' | 'es_ES' |
  'fr_BE' | 'fr_CH' | 'fr_FR' | 'fr_LU' | 'hu_HU' | 'it_CH' | 'it_IT' | 'nl_BE' | 'nl_NL' | 'no_NO' | 'pl_PL' | 'pt_PT' | 'ru_RU' | 'sv_SE' | 'tr_TR';

export const languageTypeList = ['ca_ES', 'de_AT', 'de_CH', 'de_DE', 'de_LU', 'el_GR', 'en_GB', 'es_ES',
  'fr_BE', 'fr_CH', 'fr_FR', 'fr_LU', 'hu_HU', 'it_CH', 'it_IT', 'nl_BE', 'nl_NL', 'no_NO', 'pl_PL', 'pt_PT', 'ru_RU', 'sv_SE', 'tr_TR'];

export type AgeRestriction = 0 | 6 | 12 | 16 | 18;

export const ageRestrictionsList = [0, 6, 12, 16, 18];

export interface PageResponse {
  first: boolean;
  last: boolean;
  empty: boolean;
  pageable: {
    pageSize: number;
    pageNumber: number;
  };
  perPage: number;
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
}

const pageResponseOriginal: PageResponse = {
  empty: true,
  first: false,
  last: false,
  pageable: {
    pageSize: 10,
    pageNumber: 0
  },
  perPage: 10,
  totalPages: 0,
  totalElements: 0,
  numberOfElements: 0
};

export const pageResponse = ():PageResponse => {
  return JSON.parse(JSON.stringify(pageResponseOriginal));
};

export const mapCountryToLanguage = (country: Country):LanguageType => {

  switch (country) {
    case "AT": {
      return "de_AT";
    }
    case "BE": {
      return "nl_BE";
    }
    case "CH": {
      return "de_CH";
    }
    case "DE": {
      return "de_DE";
    }
    case "ES": {
      return "es_ES";
    }
    case "HU": {
      return "hu_HU";
    }
    case "IT": {
      return "it_IT";
    }
    case "LU": {
      return "de_LU";
    }
    case "NL": {
      return "nl_NL";
    }
    case "PL": {
      return "pl_PL";
    }
    case "PT": {
      return "pt_PT";
    }
    case "SE": {
      return "sv_SE";
    }
    case "TR": {
      return "tr_TR";
    }
  }
};
