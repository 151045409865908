import * as React from "react";
import { useEffect, useState } from "react";
import ProductGroupsService from "../../services/ProductGroupService";
import { LoadableSelect, LoadableSelectItem } from "./LoadableSelect";
import { Alert } from "antd";
import { Country, mapCountryToLanguage } from "../../store/common/types";
import {ProductType} from "../../store/productGroups/types";

export interface Props {
  country: Country;
  value?: number;
  productType: ProductType;
  onChange?: (value: number) => void;
  placeholder? : string
}

export const ProductGroupLoadableSelect: React.FunctionComponent<Props> = (props) => {

  const [error, setError] = React.useState(false);
  const [productGroup, setProductGroup] = useState<LoadableSelectItem>(null);
  const [loadOptionsTrigger, setLoadOptionsTrigger] = useState(0);

  const {placeholder = "Search product group by id or name"} = props;
  const setProductGroupAndNotify = (productGroup: any) => {
    setProductGroup(productGroup);
    props.onChange(productGroup ? (productGroup.value as number) : undefined);
  };

  useEffect(() => {

    setLoadOptionsTrigger(Math.random() * 10000.00);

  }, [props.country, props.productType]);

  useEffect(() => {

    if (props.value) {

      ProductGroupsService
        .getProductGroup({
          productType: props.productType,
          id: props.value,
          language: mapCountryToLanguage(props.country),
        })
        .then(productGroup => {

          setProductGroup({
            value: productGroup.item.id,
            label: productGroup.item.id + " - " + productGroup.item.description
          });
        })
        .catch(error => {
          setError(true);
          setProductGroup(null);
        });
    } else {
      setProductGroup(null);
    }
  }, [props.value]);

  const searchGroups = (searchInput: string, page: number): Promise<LoadableSelectItem[]> => {

    return !props.country ? Promise.resolve([]) : ProductGroupsService.searchProductGroups({
      productType: props.productType,
      language: mapCountryToLanguage(props.country),
      search: searchInput,
      page: page,
      size: 1000
    })
      .then(response => {
        return response.content.map(
          (productGroup) => {
            return {
              value: productGroup.id,
              label: productGroup.id + " - " + productGroup.description
            }
          }
        );
      })
      .catch(error => {
        setError(true);
        throw error;
      });
  };

  return (
    <React.Fragment>
      {
        error && <Alert message="Error loading data" type="error" showIcon closable onClose={() => {
          setError(false)
        }}/>
      }
      <LoadableSelect
        value={productGroup || undefined}
        placeholder= {placeholder}
        fetchOptions={searchGroups}
        loadOptionsTrigger={loadOptionsTrigger}
        onChange={newValue => {
          setProductGroupAndNotify(newValue);
        }}
        style={{ width: '100%' }}
      />
    </React.Fragment>
  );
};

export default ProductGroupLoadableSelect;
