import * as React from "react";
import "./ProductSearchListView.css"
import EnhancedTable from "../controls/EnhancedTable/EnhancedTable";
import {Form, notification, Spin, Typography} from 'antd';
import {
    FetchProductSearchPageResponse,
    FetchProductSearchItemsPageRequest,
    ProductSearch
} from "../../store/productSearch/types";
import Search from "antd/es/input/Search";
import {countriesList, Country, languageTypeList} from "../../store/common/types";

import LanguageTypeSelect from "../LanguageTypeSelect/LanguageTypeSelect";
import CountrySelect from "../LanguageTypeSelect/CountrySelect";
import {CloseCircleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

export interface Props {
    pageRequest: FetchProductSearchItemsPageRequest;
    pageResponse: FetchProductSearchPageResponse;

    onPaginationChanged: (page: number, size: number) => void;
    onFilterChanged?: (filterField: string, value: string) => void;
    onRowClick?: (country: Country, number: number) => void;

    loading: boolean;
    error: string;
}

export const ProductSearchListView: React.FunctionComponent<Props> = (props) => {

    const {t} = useTranslation();

    const [form] = Form.useForm();

    const {
        pageRequest,
        pageResponse,
        onPaginationChanged,
        onFilterChanged,
        onRowClick,
        loading
    } = props;

    const columns = [

        {
            title: t("product.id"),
            render: (item: ProductSearch) => {
                return (<Typography.Link
                    onClick={() => onRowClick(item.country, item.product_id)}>{item.product_id}</Typography.Link>);
            }
        },
        {
            title: 'EAN',
            dataIndex: 'ean_code',
            render: (item: ProductSearch) => {
                return (item);
            }
        },
        {
            title: t("product.name"),
            dataIndex: 'product_name',
            render: (item: ProductSearch) => {
                return (item);
            }
        },
        {
            title: t("product.globalId"),
            dataIndex: 'product_group_id',
            render: (item: ProductSearch) => {
                return (item);
            }
        },
        {
            title: t("product.group.name"),
            dataIndex: 'product_group_name',
            render: (item: ProductSearch) => {
                return (item);
            }
        },
        {
            title: t("general.country"),
            dataIndex: 'country',
            render: (item: ProductSearch) => {
                return (item)
            }
        },
        {
            title: t("general.language"),
            dataIndex: 'language',
            render: (item: ProductSearch) => {
                return (item)
            }
        },
        {
            title: t("product.manufacturer.id"),
            dataIndex: 'manufacturer_id',
            render: (item: ProductSearch) => {
                return (item)
            }
        },
        {
            title: t("product.manufacturer.name"),
            dataIndex: 'manufacturer_name',
            render: (item: ProductSearch) => {
                return (item)
            }
        },
        {
            title: t("product.supplier.id"),
            dataIndex: 'supplier_id',
            render: (item: ProductSearch) => {
                return (item)
            }
        },
        {
            title: t("product.supplier.name"),
            dataIndex: 'supplier_name',
            render: (item: ProductSearch) => {
                return (item)
            }
        },
        {
            title: t("product.releaseDate"),
            dataIndex: 'release_date',
            render: (item: ProductSearch) => {
                return (item)
            }
        },
        {
            title: t("product.cancelDate"),
            dataIndex: 'cancel_date',
            render: (item: ProductSearch) => {
                return (item)
            }
        },
        {
            title: t("product.deleteFlag"),
            dataIndex: 'to_be_deleted_flag',
            render: (item: ProductSearch) => {
                return (item)
            }
        }
    ];

    const openNotification = () => {
        notification.open({
            message: 'Error Notification',
            description: 'Please Enter Valid EAN/GTIN',
            icon: <CloseCircleOutlined style={{ color: '#e51010' }} />,
            onClick: () => {
                console.log('Invalid EAN/GTIN');
            },
        });
    };

    const handleFilterChange = (field: string, value: string) => {

        if (field == 'ean' && !value) {
            openNotification();
        } else {
            onFilterChanged && onFilterChanged(field, value);
        }
    };

    const formItemLayout = {
        labelCol: {span: 2},
        wrapperCol: {span: 8},
    };
    return props.error ?
        (
            <div className="view-error">{props.error}</div>
        )
        :
        (
            <React.Fragment>

                {
                    loading && (<div>
                        <div className="view-loading"/>
                        <Spin className="view-loading-spinner"/>
                    </div>)
                }


                <Form
                    {...formItemLayout}
                    layout={'horizontal'}
                    form={form}
                >
                    <Form.Item label= {t("general.country")}>
                        <CountrySelect id={"country-select"}
                                       value={pageRequest.country}
                                       list={countriesList}
                                       placeholder={t("product.country.placeholder")}
                                       onChange={(value) => handleFilterChange('country', value)}
                        />
                    </Form.Item>
                    <Form.Item label={t("general.language")}>
                        <LanguageTypeSelect id={"language-select"}
                                            value={pageRequest.language}
                                            list={languageTypeList}
                                            onChange={(value) => handleFilterChange('language', value)}
                        />
                    </Form.Item>
                    <Form.Item label={'EAN/GTIN'}
                               name={'ean'}
                               rules={[{
                                   required: true, pattern: new RegExp("^[0-9]{8,13}$"), message: t("product.ean.validation")
                               }]}>
                        <Search
                            placeholder= {t("product.ean.search.placeholder")}
                            enterButton
                            size="middle"
                            onSearch={(value) => handleFilterChange('ean', value)}
                        />
                    </Form.Item>
                </Form>

                <EnhancedTable tableName={t("general.result")}
                               columns={columns}
                               data={pageResponse.content}
                               totalCount={pageResponse.totalElements}
                               countOnPage={pageResponse.numberOfElements}
                               currentPage={pageRequest.page}
                               pageSize={pageRequest.size}
                               onPaginationChanged={onPaginationChanged}/>
            </React.Fragment>
        );
};

export default ProductSearchListView;
