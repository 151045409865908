import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_FAILED,
  CREATE_PRODUCT_SUCCEEDED,
  CreateProductRequest,
  CreateProductResponse,
  FETCH_PRODUCT,
  FETCH_PRODUCT_FAILED,
  FETCH_PRODUCT_SUCCEEDED,
  FetchProductRequest,
  FetchProductResponse,
  ProductActionTypes
} from "./types";

export function createProduct(payload: CreateProductRequest): ProductActionTypes {
  return {
    type: CREATE_PRODUCT,
    payload
  }
}

export function createProductSucceeded(payload: CreateProductResponse): ProductActionTypes {
  return {
    type: CREATE_PRODUCT_SUCCEEDED,
    payload
  }
}

export function createProductFailed(error: string): ProductActionTypes {
  return {
    type: CREATE_PRODUCT_FAILED,
    error
  }
}

export function fetchProduct(payload: FetchProductRequest): ProductActionTypes {
  return {
    type: FETCH_PRODUCT,
    payload
  }
}

export function fetchProductSucceeded(payload: FetchProductResponse): ProductActionTypes {
  return {
    type: FETCH_PRODUCT_SUCCEEDED,
    payload
  }
}

export function fetchProductFailed(error: string): ProductActionTypes {
  return {
    type: FETCH_PRODUCT_FAILED,
    error
  }
}
