import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects'
import { userSessionReducer } from "./userSession/reducers";
import userSessionSaga from "./userSession/sagas";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from 'history'
import { productReducer } from "./product/reducers";
import { productSaga } from "./product/sagas";
import {userSaga} from "./user/sagas";
import {userReducer} from "./user/reducers";
import {productSearchSaga} from "./productSearch/sagas"
import {productSearchReducer} from "./productSearch/reducers";

export const history = createBrowserHistory({
    basename: `/`,
});

const rootReducer = combineReducers({
    router: connectRouter(history),
    userSession: userSessionReducer,
    product: productReducer,
    user: userReducer,
    productSearch: productSearchReducer
});

const rootSaga = function* rootSaga() {
    yield all([
        userSessionSaga(),
        productSaga(),
        userSaga(),
        productSearchSaga()
    ])
};

export type AppState = ReturnType<typeof rootReducer>

const sagaMiddleware = createSagaMiddleware();

export const configureStore = () => {
    let composeEnhancers = compose;

    if ('__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' in window) {
        composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }

    return createStore(rootReducer,
        composeEnhancers(applyMiddleware(
            routerMiddleware(history),
            sagaMiddleware
        )));
};

export const store = configureStore();

sagaMiddleware.run(rootSaga);
