import axiosInstance from '../axiosInstance'
import { AxiosInstance } from "axios";
import { resolveApiBaseUrl } from "../environment";
import * as qs from "qs";
import { SearchManufacturerPageRequest, SearchManufacturerPageResponse } from "../store/manufacturer/types";

class ManufacturerService {
  axios: AxiosInstance;

  constructor () {
    this.axios = axiosInstance;
  }

  searchManufacturers(pageRequest: SearchManufacturerPageRequest): Promise<SearchManufacturerPageResponse> {

    const params = {
      page: pageRequest.page,
      size: pageRequest.size
    } as any;

    if (pageRequest.mdngId) {
      params["mdngId"] = pageRequest.mdngId;
    }

    if (pageRequest.search) {
      params["search"] = pageRequest.search;
    }

    if (pageRequest.country) {
      params["country"] = pageRequest.country;
    }

    if (pageRequest.deleted !== undefined && pageRequest.deleted !== null) {
      params["deleted"] = pageRequest.deleted;
    }

    params.sort = "id,ASC";

    return this.axios.get(`/gateway/manufacturers-api/manufacturers`, {
      baseURL: resolveApiBaseUrl(),
      params: params,
      paramsSerializer: {
        serialize: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    }
    })
      .then((response: any) => {
        return {
          ...response['data']
        } as SearchManufacturerPageResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  handleError(error: any) {
    console.error('ManufacturersService:', error)
  }
}

export default new ManufacturerService()
