import * as React from "react";
import {ReactNode} from "react";
import "../PageWrapper/PageWrapper.css"
import {Layout, Menu} from 'antd';
import {
    LoginOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined
} from "@ant-design/icons/lib";
import {resolveApiBaseUrl} from "../../environment";
import LanguageIcon from "../LanguageIcon/LanguageIcon";
import {useTranslation} from "react-i18next";

interface Props {
    children: ReactNode;
}

const {Header, Sider, Content} = Layout;

export default function PageWrapper(props: Props) {

    const [collapsed] = React.useState(false);

    const {t} = useTranslation();

    const redirectToLogin = () => {
        window.location.href = window.location.href = resolveApiBaseUrl() + '/';
    };

    return (

        <Layout style={{
            height: '100vh'
        }}>
            <Sider trigger={null} collapsible collapsed={!collapsed}>
                <div className="logo"/>
                <Menu theme="dark" mode="inline">
                    <Menu.Item key={'1'}>
                        <a onClick={redirectToLogin}>
                            {<LoginOutlined/>}
                            <span>{'Back'}</span>
                        </a>
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{padding: 0}}>
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger'
                    })}
                    <div style={{display: 'inline-flex', fontSize: '18px', color: 'rgba(0, 0, 0, 0.85)'}}><strong>{t("cap.offline.title")}</strong></div>
                    <LanguageIcon style={{float: 'right', paddingRight: '30px'}} />
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '2px 2px',
                        padding: 24,
                        minHeight: 'unset',
                        overflowY: 'auto'
                    }}>
                    {props.children}
                </Content>
            </Layout>
        </Layout>
    );
}
