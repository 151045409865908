import i18next from "i18next";
import {initReactI18next} from "react-i18next";

// @ts-ignore
import enEN from "./translation/en.json";
// @ts-ignore
import deDE from "./translation/de.json";

const resources = {
    en: {
        translation: enEN
    },
    de: {
        translation: deDE
    }
}

i18next.use(initReactI18next)
    .init({
        resources,
        lng: "de"
    });

export default i18next;
