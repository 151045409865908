import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import {UserSession} from "../../store/userSession/types";
import { PageHeader } from 'antd';
import {getUser} from "../../store/user/actions";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import {GetUserRequest, UserResponse} from "../../store/user/types";
import {AppState} from "../../store";
import UserDetailView from "../../components/UserDetail/UserDetailView";
import UserService from "../../services/UserService";

interface Props extends RouteComponentProps<{username: string}> {
  username: string;
  getUser: (getUserRequest: GetUserRequest) => UserResponse;
  getUserRequest: GetUserRequest;
  getUserResponse: UserResponse;
  userSession: UserSession;
  loading?: boolean;
  error?: string;
}

interface State {
  email: string
}

class UserPage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      email: new URLSearchParams(this.props.location.search).get("u"),
    }

    this.handleDeletePermission = this.handleDeletePermission.bind(this)
  }

  componentDidMount() {
    this.props.getUser({
      ...this.props.getUserRequest,
      email: this.state.email
    })
  }

  public render() {

    return (
      <div className="product-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={`User`}
            subTitle={`User account details`}>
          </PageHeader>

        <UserDetailView
            onDeletePermission={this.handleDeletePermission}
            user={this.props.getUserResponse}
            error={this.props.error}
            loading={this.props.loading}/>
        </PageWrapper>
      </div>
    )
  }

  handleDeletePermission(permission: string) {
    UserService.deletePermission(this.state.email, permission)
        .then(() => window.location.reload())
        .catch(() => alert("Make sure you have correct permissions or contact to administrators"))
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,
  getUserRequest: state.user.getUserRequest,
  getUserResponse: state.user.getUserResponse,
  loading: state.user.loading,
  error: state.user.error
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getUser: (getUserRequest: GetUserRequest) => {
    dispatch(getUser(getUserRequest))
  }
})

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(UserPage)
