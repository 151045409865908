import axiosInstance from '../axiosInstance'
import { AxiosInstance } from "axios";
import { resolveApiBaseUrl } from "../environment";
import { Country } from "../store/common/types";
import { Tax } from "../store/tax/types";

class TaxService {
  axios: AxiosInstance;

  constructor () {
    this.axios = axiosInstance;
  }

  getTaxValues(country: Country): Promise<Tax[]> {

    return this.axios.get(`/gateway/taxes-api/tax-regions`, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        const taxRegions = response['data'].tax_regions.filter((region: any) => region.country === country);
        const taxValuesPromises = taxRegions.map((taxRegion: any) => this.getTaxValuesForRegion(taxRegion.id));

        return Promise.all(taxValuesPromises).then((values) => {

          const taxValues = [] as Tax[];

          values.forEach((arr: Tax[]) => {
            arr.forEach((taxValue: Tax) => taxValues.push(taxValue))
          });

          return taxValues;
        });
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  getTaxValuesForRegion(regionId: number): Promise<Tax[]> {

    return this.axios.get(`/gateway/taxes-api/tax-regions/${regionId}/value-added-taxes`, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return response['data'].value_added_taxes
          .map((taxValue: any) => { return { id: taxValue.id, name: taxValue.name, values: taxValue.values}});
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  handleError(error: any) {
    console.error('TaxService:', error)
  }
}

export default new TaxService()
