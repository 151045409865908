import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./HomePage.css";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import {Timeline, Typography} from "antd";
import {withTranslation} from "react-i18next";

interface Props extends RouteComponentProps {
  userSession: UserSession;
  loading?: boolean;
  error?: string;
}

class HomePage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {

    // @ts-ignore
    const {t} = this.props;

    if(this.props.userSession.permissions && (this.props.userSession.permissions.indexOf('Admin') > -1 ||
        this.props.userSession.permissions.indexOf('User') > -1)) {
      return (
          <div className="home-page">
            <PageWrapper userSession={this.props.userSession}>
              <h2>{t("homepage.welcome")}!</h2>

              <p>{t("homepage.note1")}</p>
              <p>{t("homepage.note2")}</p>
              <br/>

              <p>{t(t("homepage.note3"))}</p>
              <br/>

              <Timeline>
                <Timeline.Item color="blue">
                  <p><strong>{t("homepage.create")}</strong></p>
                  <p>{t("homepage.create.note")}</p>
                </Timeline.Item>
                <Timeline.Item color="red">
                  <p><strong>{t("homepage.search")}</strong></p>
                  <p>{t("homepage.search.note")}</p>
                </Timeline.Item>
                <Timeline.Item color="green">
                  <p><strong>{t("homepage.edit")}</strong></p>
                  <p>{t("homepage.edit.note")}</p>
                </Timeline.Item>
              </Timeline>

              <br/>

              <p>{t("homepage.note4")}</p>
              <br/>

              <Timeline>
                <Timeline.Item color="blue">
                  <p><strong>{t("homepage.admin")}</strong></p>
                  <p>{t("homepage.admin.note1")}</p>
                  <p>{t("homepage.admin.note2")}</p>
                  <p>{t("homepage.admin.note3")}</p>
                  <p>{t("homepage.admin.note4")}</p>
                </Timeline.Item>
                <Timeline.Item color="green">
                  <p><strong>{t("homepage.user")}</strong></p>
                  <p>{t("homepage.user.note1")}</p>
                  <p>{t("homepage.user.note2")}</p>
                </Timeline.Item>
              </Timeline>

              <br/>

            </PageWrapper>
          </div>
      );
    } else {
      return (
          <div className="home-page">
            <PageWrapper userSession={this.props.userSession}>
               <h2>{t("homepage.welcome")}!</h2>

              <p>{t("homepage.note1")}</p>
              <p>{t("homepage.note2")}</p>
              <br/>

              <p><h2><Typography.Text type="danger">{t("homepage.permission.alarm")}</Typography.Text></h2></p>
              <br/>

              {t("homepage.permission.note1")}
              <br/><br/>
              <Timeline>
                <Timeline.Item color="blue">
                  <p><strong>{t("homepage.permission.option1")}</strong></p>
                  <p>{t("homepage.permission.option1.note")}</p>
                </Timeline.Item>
                <Timeline.Item color="red">
                  <p><strong>{t("homepage.permission.option2")}</strong></p>
                  <p>Please create a <a href={"https://mms.cherwellondemand.com/"} target="_blank">Cherwell</a> ticket.</p>
                  <p>{t("homepage.permission.option2.note")}</p>
                </Timeline.Item>
                <Timeline.Item color="green">
                  <p><strong>{t("homepage.permission.option3")}</strong></p>
                  <p>Please use <a href={"/app/cap-offline"}>Offline CAP Application</a> for creating a product manually.</p>
                </Timeline.Item>
              </Timeline>

              <br/>
            </PageWrapper>
          </div>
      );
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default withTranslation() (enhance(HomePage));
