import * as React from "react";
import { useEffect, useState } from "react";
import { LoadableSelect, LoadableSelectItem } from "./LoadableSelect";
import ManufacturersService from "../../services/ManufacturerService";
import { Alert } from "antd";
import { Country } from "../../store/common/types";
import SupplierService from "../../services/SupplierService";

export interface Props {
  country: Country;
  value?: number;
  onChange?: (value: number) => void;
  placeholder? : string
}

export const ManufacturerLoadableSelect: React.FunctionComponent<Props> = (props) => {

  const [error, setError] = React.useState(false);
  const [manufacturer, setManufacturer] = useState<LoadableSelectItem>(null);
  const [loadOptionsTrigger, setLoadOptionsTrigger] = useState(0);

  const {placeholder = "Search manufacturer by id or name"} = props;
  const setManufacturerAndNotify = (manufacturer: any) => {
    setManufacturer(manufacturer);
    props.onChange(manufacturer ? (manufacturer.value as number) : undefined);
  };

  useEffect(() => {
    setLoadOptionsTrigger(Math.random() * 10000.00);

  }, [props.country]);

  useEffect(() => {

    if (props.value) {

      searchManufacturers(props.value, null, 0)
        .then(manufacturers => {

          if (manufacturers.length == 1) {
            setManufacturer(manufacturers[0]);
          }
        })
        .catch(error => {
          setManufacturer(null);
          setError(true);
        });
    } else {
      setManufacturer(null);
    }
  }, [props.value]);

  const searchManufacturers = (mdngId: number, searchInput: string, page: number): Promise<LoadableSelectItem[]> => {

    const searchId = (searchInput && /^[0-9]+$/.test(searchInput.trim()));

    return !props.country ? Promise.resolve([]) : ManufacturersService.searchManufacturers({
      mdngId: mdngId || (searchId ? parseInt(searchInput.trim()) : null),
      search: searchId ? null : searchInput,
      country: props.country,
      deleted: false,
      page: page,
      size: 1000
    })
      .then(response => {
        return response.content.map(
          (manufacturer) => {
            return {
              value: manufacturer.mdngId,
              label: manufacturer.mdngId + " - " + manufacturer.name
            }
          }
        );
      }).catch(error => {
        setError(true);
        throw error;
      });
  };

  return (
    <React.Fragment>
      {
        error && <Alert message="Error loading data" type="error" showIcon closable onClose={() => {
          setError(false)
        }}/>
      }
      <LoadableSelect
        value={manufacturer || undefined}
        placeholder={placeholder}
        fetchOptions={(search, page) => searchManufacturers(null, search, page)}
        loadOptionsTrigger={loadOptionsTrigger}
        onChange={newManufacturer => {
          setManufacturerAndNotify(newManufacturer);
        }}
        style={{ width: '100%' }}
      />
    </React.Fragment>
  );
};

export default ManufacturerLoadableSelect;
