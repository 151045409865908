import * as React from "react";
import { useEffect, useState } from "react";
import { LoadableSelect, LoadableSelectItem } from "./LoadableSelect";
import SupplierService from "../../services/SupplierService";
import { Alert } from "antd";
import { Country } from "../../store/common/types";
import {ProductType} from "../../store/productGroups/types";

export interface Props {
  country: Country;
  manufacturerId: number;
  value?: number;
  onChange?: (value: number) => void;
  productType: ProductType;
  placeholder? : string;
}

export const SupplierLoadableSelect: React.FunctionComponent<Props> = (props) => {

  const [error, setError] = React.useState(false);
  const [supplier, setSupplier] = useState<LoadableSelectItem>(null);
  const [loadOptionsTrigger, setLoadOptionsTrigger] = useState(0);

  const {placeholder = "Search supplier by id or name"} = props;

  const setSupplierAndNotify = (supplier: any) => {
    setSupplier(supplier);
    props.onChange(supplier ? (supplier.value as number) : undefined);
  };

  useEffect(() => {

    if (props.value && props.manufacturerId) {

      SupplierService
        .getSupplier({
          productType: props.productType,
          manufacturerId: props.manufacturerId,
          supplierId: props.value,
          country: props.country
        })
        .then(supplier => {

          setSupplier({
            value: supplier.supplier_id,
            label: supplier.supplier_id + " - " + supplier.supplier_name
          });
        })
        .catch(error => {
          setSupplier(null);
          setError(true);
        });
    } else {
      setSupplier(null);
    }
  }, [props.value]);

  useEffect(() => {

    setLoadOptionsTrigger(Math.random() * 10000.00);

  }, [props.manufacturerId, props.country, props.productType]);

  const searchSuppliers = (searchInput: string, page: number): Promise<LoadableSelectItem[]> => {

    return (!props.manufacturerId || !props.country) ? Promise.resolve([]) : SupplierService.searchSuppliers({
      productType: props.productType,
      manufacturerId: props.manufacturerId,
      country: props.country,
      search: searchInput,
      page: page,
      size: 1000
    })
      .then(response => {
        return response.content.map(
          (supplier) => {
            return {
              value: supplier.supplier_id,
              label: supplier.supplier_id + " - " + supplier.supplier_name
            }
          }
        );
      }).catch(error => {
        setError(true);
        throw error;
      });
  };

  return (
    <React.Fragment>
      {
        error && <Alert message="Error loading data" type="error" showIcon closable onClose={() => {setError(false)}}/>
      }
      <LoadableSelect
        value={supplier || undefined}
        placeholder={placeholder}
        fetchOptions={searchSuppliers}
        loadOptionsTrigger={loadOptionsTrigger}
        onChange={newSupplier => {
          setSupplierAndNotify(newSupplier)
        }}
        style={{ width: '100%' }}
      />
    </React.Fragment>
  );
};

export default SupplierLoadableSelect;
