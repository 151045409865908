import {Country, LanguageType, PageResponse} from "../common/types";

export const FETCH_PRODUCT_SEARCH = 'FETCH_PRODUCT_SEARCH'
export const FETCH_PRODUCT_SEARCH_SUCCEEDED = 'FETCH_PRODUCT_SEARCH_SUCCEEDED'
export const FETCH_PRODUCT_SEARCH_FAILED = 'FETCH_PRODUCT_SEARCH_FAILED'

export const CLOSE_PRODUCT_SEARCH_ERROR_MESSAGE = 'CLOSE_PRODUCT_SEARCH_ERROR_MESSAGE'

export interface FetchProductSearchItemRequest {
  ean: string;
  country: Country;
  language: LanguageType;
}

export interface FetchProductSearchItemsPageRequest {
  page: number;
  size: number;
  ean: string;
  country: Country;
  language: LanguageType;
}

export interface FetchProductSearchPageResponse extends PageResponse {
  content: ProductSearch[];
}

export class ProductSearch {
  ean_code: string;
  product_id: number;
  product_name: string;
  product_group_id: number;
  product_group_name: string;
  country: Country;
  language: LanguageType;
  manufacturer_id: number;
  manufacturer_name: string;
  supplier_id: number;
  supplier_name: number;
  release_date: string;
  cancel_date: string;
  to_be_deleted_flag: number;
}

export interface ProductSearchState {

  pageRequest: FetchProductSearchItemsPageRequest;
  pageResponse: FetchProductSearchPageResponse;

  itemRequest: FetchProductSearchItemRequest;
  
  ean: string;
  loading: boolean;
  error: string;
}

export interface FetchProductSearchAction {
  type: typeof FETCH_PRODUCT_SEARCH
  pageRequest: FetchProductSearchItemsPageRequest
}

export interface FetchProductSearchSucceededAction {
  type: typeof FETCH_PRODUCT_SEARCH_SUCCEEDED
  payload: FetchProductSearchPageResponse
}

export interface FetchProductSearchFailedAction {
  type: typeof FETCH_PRODUCT_SEARCH_FAILED
  error: string
}

export interface CloseProductSearchErrorMessageAction {
  type: typeof CLOSE_PRODUCT_SEARCH_ERROR_MESSAGE
}

export type ProductSearchActionTypes = FetchProductSearchAction | FetchProductSearchSucceededAction | FetchProductSearchFailedAction
    | CloseProductSearchErrorMessageAction;