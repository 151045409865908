import axiosInstance from '../axiosInstance'
import { AxiosInstance } from "axios";
import { resolveApiBaseUrl } from "../environment";
import * as qs from "qs";

import {AssignUserPermissionRequest, FindPermissionsRequest, FindPermissionsResponse} from "../store/permission/types";

class PermissionService {
  axios: AxiosInstance;

  constructor () {
    this.axios = axiosInstance;
  }

  findPermissions(findPermissionsRequest: FindPermissionsRequest): Promise<FindPermissionsResponse> {

    const params = {
      offset: findPermissionsRequest.offset,
      limit: findPermissionsRequest.limit,
      search: findPermissionsRequest.search
    } as any;

    return this.axios.get(`/permission`, {
        baseURL: resolveApiBaseUrl(),
        params: params,
        paramsSerializer: {
            serialize: params => {
            return qs.stringify(params, {
                arrayFormat: 'repeat'
            })
        }
    }
    })
        .then((response: any) => {
          return {
            ...response['data']
          } as FindPermissionsResponse;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  assignPermissions(assignUserPermissionRequest: AssignUserPermissionRequest): Promise<void> {

    const payload = {
      permission: assignUserPermissionRequest.permission
    }

    return this.axios.put(`/user-permission/user/${assignUserPermissionRequest.username}/permission`, payload, {
        baseURL: resolveApiBaseUrl(),
        data: {
            permission: assignUserPermissionRequest.permission
        },
        paramsSerializer: {
            serialize: params => {
            return qs.stringify(params, {
                arrayFormat: 'repeat'
            })
        }
    }
    })
        .then((response: any) => {
          return {
            ...response['data']
          } as void;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  handleError (error: any) {
    console.error('PermissionService:', error)
  }
}

export default new PermissionService()
