import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { AppState, store } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import { PageHeader } from 'antd';
import { ProductDetailsView } from "../../components/ProductDetails/ProductDetailsView";
import { Product } from "../../store/product/types";
import { createProduct, fetchProduct, fetchProductSucceeded } from "../../store/product/actions";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import {withTranslation} from "react-i18next";

interface Props extends RouteComponentProps<{productId: string, country: string}> {
  userSession: UserSession;
  newProduct: Product;
  existingProduct: Product;
  fetchProduct: (productId: number, country: string) => void;
  createProduct: (product: Product) => void;
  updateProduct: (product: Product) => void;
  loading?: boolean;
  error?: string;
}

interface State {
}

class ProductPage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.onProductCreate = this.onProductCreate.bind(this);
    this.onProductUpdate = this.onProductUpdate.bind(this);
  }

  componentDidMount() {

    if (this.props.match.params.country && this.props.match.params.productId) {
      this.props.fetchProduct(parseInt(this.props.match.params.productId), this.props.match.params.country)
    } else {
      store.dispatch(fetchProductSucceeded({
        product: null
      }));
    }
  }

  private onProductCreate(product: Product) {
    this.props.createProduct(product);
  }

  private onProductUpdate(product: Product) {
    this.props.updateProduct(product);
  }

  public render() {

  // @ts-ignore
  const {t} = this.props;

    return (
      <div className="product-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title= {t("product.title.create")}
            subTitle={""}
          >
          </PageHeader>
          <ProductDetailsView
            editMode={!!this.props.match.params.country && !!this.props.match.params.productId}
            editModeCountry={this.props.match.params.country}
            userSession={this.props.userSession}
            product={(!!this.props.match.params.country && !!this.props.match.params.productId) ? this.props.existingProduct : this.props.newProduct}
            onProductCreate={this.onProductCreate}
            onProductUpdate={this.onProductUpdate}
            loading={this.props.loading}
            error={this.props.error}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,

  newProduct: state.product.newProduct,
  existingProduct: state.product.existingProduct,

  loading: state.product.loading,
  error: state.product.error
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchProduct: (productId: number, country: string) => {
    dispatch(fetchProduct({productId, country}))
  },
  createProduct: (product: Product) => {
    dispatch(createProduct({product}))
  },
  updateProduct: (product: Product) => {
    dispatch(createProduct({product}))
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default withTranslation()(enhance(ProductPage));
