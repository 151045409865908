import * as React from "react";
import {
    Table,
    Timeline
} from 'antd';
import {resolveApiBaseUrl} from "../../environment";
import axios from "axios";
import {useTranslation} from "react-i18next";

export interface Props {
    lightView: boolean;
}
export const CAPOfflineView: React.FunctionComponent<Props> = (props) => {

    const {t} = useTranslation();
    const { lightView } = props;
    let handleDownload = (filename: string) => {
        axios({
            url: resolveApiBaseUrl() + '/download-template/' + filename,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        });
    }

    let dataSource = [
        {
            key: '1',
            country: 'AT',
            template: <a onClick={() => handleDownload('at_cap_request.xls')}
                         download="at_cap_request.xls"> Download</a>,
            mdTeam: <a
                href="mailto:schuetzs@mediamarkt.at?subject=CAP%20Manual%20request&body=Please%20process%20the%20attached%20CAP%20request.">
                AT Stammdaten</a>
        },
        {
            key: '2',
            country: 'BE',
            template: <a onClick={() => handleDownload('be_cap_request.xlsx')}
                         download="be_cap_request.xlsx">Download</a>,
            mdTeam: <a
                href="mailto:databasebel@media-saturn.com?subject=CAP%20Manual%20request&body=Please%20process%20the%20attached%20CAP%20request.">
                Database Team BE</a>
        },
        {
            key: '3',
            country: 'DE',
            template: <a onClick={() => handleDownload('de_cap_request_tt.xlsx')}
                         download="de_cap_request_tt.xlsx">Download
                TT</a>,
            mdTeam: <a
                href="mailto:sc.stammdaten.wws&dws@media-saturn.com?subject=CAP%20Manual%20request&body=Please%20process%20the%20attached%20CAP%20request.">
                MSD Stammdatenanl. WWS</a>
        },
        {
            key: '4',
            country: 'DE',
            template: <a onClick={() => handleDownload('de_cap_request_ue.xlsx')}
                         download="de_cap_request_ue.xlsx">Download
                UE</a>,
            mdTeam: <a
                href="mailto:sc.stammdaten.wws&dws@media-saturn.com?subject=CAP%20Manual%20request&body=Please%20process%20the%20attached%20CAP%20request.">
                MSD Stammdatenanl. WWS</a>
        },
        {
            key: '5',
            country: 'PT',
            template: <a onClick={() => handleDownload('pt_cap_request.xlsx')}
                         download="pt_cap_request.xlsx">Download</a>,
            mdTeam: <a
                href="mailto:md_pt@media-saturn.com?subject=CAP%20Manual%20request&body=Please%20process%20the%20attached%20CAP%20request.">
                MD Maintenance PT</a>
        },
    ];

    const columns = [
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
        },
        {
            title: 'Template',
            dataIndex: 'template',
            key: 'template',
        },
        {
            title: 'MD Team',
            dataIndex: 'mdTeam',
            key: 'mdTeam',
        },
    ];

    let userNote;
    let pageFooterNote;
    if (lightView && lightView == true) {
        userNote = t("offline.lightView.userNote");
        pageFooterNote = "";
        dataSource = [
            {
                key: '4',
                country: 'DE',
                template: <a onClick={() => handleDownload('de_cap_Artikel_Neulistung.xlsx')}
                             download="de_cap_Artikel_Neulistung.xlsx">Download
                    UE</a>,
                mdTeam: <a
                    href="mailto:sc.stammdaten.wws&dws@media-saturn.com?subject=CAP%20Manual%20request&body=Please%20process%20the%20attached%20CAP%20request.">
                    MSD Stammdatenanl. WWS</a>
            }
        ]
    } else {
        userNote = <span>{t("offline.fullView.userNote1")}<br/>{t("offline.fullView.userNote2")}</span>
        pageFooterNote = <span>
            {t("offline.fullView.footerNote1")}<br/>
            {t("offline.fullView.footerNote2")} <a href="mailto:masterdata.ng.development@mediamarktsaturn.com?subject=CAP%20Team%20missing&body=Our%20country%20team%20is%20missing.%20Could%20you%20please%20update%20the%20list%20with%20the%20following%20country:"> Group
            {t("offline.fullView.footerNote3")} </a>.
        </span>
    }

    return <React.Fragment>

        <div>

            <strong>{t("offline.userNote.header")}</strong> <br/><br/>
            {userNote}

            <br/><br/>

            <Timeline>
                <Timeline.Item color="blue">
                    <p><strong>{t("general.download")}</strong></p>
                    <p>{t("offline.userNote.download.part1")} <strong>(Template)</strong> {t("offline.userNote.download.part2")}
                        <strong> (English only!)</strong>.</p>
                </Timeline.Item>
                <Timeline.Item color="red">
                    <p><strong>{t("general.fillOut")}</strong></p>
                    <p>{t("offline.userNote.fillOut")}</p>
                </Timeline.Item>
                <Timeline.Item color="green">
                    <p><strong>{t("general.send")}</strong></p>
                    <p>{t("offline.userNote.send")} <strong> (MD Team)</strong>.</p>
                </Timeline.Item>
            </Timeline>

            <Table dataSource={dataSource} columns={columns}/>

            {pageFooterNote}

        </div>

    </React.Fragment>
};

export default CAPOfflineView;
