import { Select } from "antd";
import * as React from "react";
import { ageRestrictionsList } from "../../store/common/types";

const { Option } = Select;

export interface Props {
  id: string
  value?: string;
  onChange?: (value: string) => void;
  mode?: 'multiple' | 'tags';
}

export default function AgeRestrictionSelect(props: Props) {

  const { id, mode, value, onChange } = props;

  return (
    <Select id={id}
            style={{width: "100%"}}
            mode={mode}
            value={value}
            placeholder="Select FSK / USK"
            onChange={(value) => {
              onChange(value)
            }}>
      {
        ageRestrictionsList.map(fsk => {
          return <Option value={fsk}>{fsk}</Option>
        })
      }
    </Select>
  )
}
