import * as React from "react";
import { useEffect, useState } from "react";
import { LoadableSelectItem } from "./LoadableSelect";
import { Country, mapCountryToLanguage } from "../../store/common/types";
import { Alert, Select, Spin } from "antd";
import MediumService from "../../services/MediumService";

export interface Props {
  country: Country;
  value?: number;
  onChange?: (value: number) => void;
}

export const MediumLoadableSelect: React.FunctionComponent<Props> = (props) => {

  const [error, setError] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [medium, setMedium] = useState<LoadableSelectItem>(null);
  const [mediums, setMediums] = useState<LoadableSelectItem[]>([]);

  function getMediumList(usePreloadedList: boolean = false): Promise<LoadableSelectItem[]> {

    if (usePreloadedList && mediums.length > 0) {
      return Promise.resolve(mediums);
    } else {
      return MediumService.getMediumList(mapCountryToLanguage(props.country))
        .then(values => {

          return values.map(
            (medium) => {
              return {
                value: medium.id,
                label: medium.shortName + " - " + medium.name
              }
            }
          );
        });
    }
  }

  useEffect(() => {

    if (props.country) {
      setFetching(true);

      getMediumList()
        .then(options => {
          setMediums(options);
          setFetching(false);
        })
        .catch(error => {
          setError(true);
        });
    } else {
      setMediums([]);
    }
  }, [props.country]);

  useEffect(() => {

    if (props.value || props.value === 0) {

      getMediumList(true)
        .then(mediums => {

          const foundMedium = mediums.filter((medium: any) => medium.value === props.value);
          if (foundMedium.length === 1) {
            setMedium(foundMedium[0]);
          }

        })
        .catch(error => {
          setError(true);
        });
    } else {
      setMedium(null);
    }
  }, [props.value]);

  return (
    <React.Fragment>
      {
        error && <Alert message="Error loading data" type="error" showIcon closable onClose={() => {setError(false)}}/>
      }
      <Select
        value={medium || undefined}
        placeholder="Select medium"
        allowClear
        labelInValue
        filterOption={true}
        onChange={newValue => {
          setMedium(newValue);
          props.onChange(newValue ? (newValue.value as number) : undefined);
        }}
        options={!fetching ? mediums : [{label: <Spin size="small" />, value: -1}]}
        style={{ width: '100%' }}
      />
    </React.Fragment>
  );
};

export default MediumLoadableSelect;
