import {Reducer} from "redux";
import {
  CREATE_USER,
  CREATE_USER_FAILED,
  CREATE_USER_SUCCEEDED,
  GET_USER,
  GET_USER_FAILED,
  GET_USER_SUCCEEDED,
  GET_USERS,
  GET_USERS_FAILED,
  GET_USERS_SUCCEEDED,
  UserActionTypes,
  UserState
} from './types'

const initialState: UserState = {
  createUserRequest: {
    email: null,
    username: null,
    country: null
  },
  createUserResponse: {
    username: null,
    email: null,
    country: null,
    createdAt: null,
    updatedAt: null,
    permissions: []
  },
  getUsersRequest: {
    page: 0,
    size: 10,
    search: ""
  },
  getUsersResponse: {
    users: [],
    total: 0
  },
  getUserResponse: {
    username: null,
    email: null,
    country: null,
    createdAt: null,
    updatedAt: null,
    permissions: []
  },
  getUserRequest: {
    email: null,
  },
  payload: null,
  loading: false,
  error: null
};

export const userReducer: Reducer<UserState, UserActionTypes> = (state: UserState = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {

    case CREATE_USER: {
      return {
        ... state,
        createUserRequest: {
          ... action.createUserRequest
        },
        loading: true,
        error: null
      }
    }

    case CREATE_USER_SUCCEEDED: {
      return {
        ...state,
        createUserResponse: {
          ...action.createUserResponse
        },
        loading: false,
        error: null
      }
    }

    case CREATE_USER_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case GET_USERS: {
      return {
        ... state,
        getUsersRequest: {
          ... action.getUsersRequest
        },
        loading: true,
        error: null
      }
    }

    case GET_USERS_SUCCEEDED: {
      return {
        ...state,
        getUsersResponse: action.getUsersResponse,
        loading: false,
        error: null
      }
    }

    case GET_USERS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case GET_USER: {
      return {
        ... state,
        getUserRequest: null,
        loading: true,
        error: null
      }
    }

    case GET_USER_SUCCEEDED: {
      return {
        ...state,
        getUserResponse: action.getUserResponse,
        loading: false,
        error: null
      }
    }

    case GET_USER_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    default:
      neverReached(action);
  }

  return state;
};


const neverReached = (never: never) => {};
