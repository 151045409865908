import * as React from "react";
import { useEffect, useState } from "react";
import { LoadableSelect, LoadableSelectItem } from "./LoadableSelect";
import PriceService from "../../services/PriceService";
import { Alert } from "antd";

export interface Props {
  supplierId: number;
  manufacturerId: number;
  priceCodeId?: number;
  onChange?: (priceCodeId: number, priceCodeName: string) => void;
}

export const PriceCodeSelect: React.FunctionComponent<Props> = (props) => {

  const [priceCode, setPriceCode] = useState<LoadableSelectItem>(null);
  const [error, setError] = useState(null);
  const [loadOptionsTrigger, setLoadOptionsTrigger] = useState(0);

  const setPriceCodeAndNotify = (option: any) => {
    setPriceCode(option);
    props.onChange(option?.value, option?.key);
  };

  useEffect(() => {
    if (props.priceCodeId && props.supplierId && props.manufacturerId) {

      PriceService.getPriceCode({
        id: props.priceCodeId,
        supplierId: props.supplierId,
        manufacturerId: props.manufacturerId
      })
        .then(pc => {
          setPriceCode({
            key: pc.name,
            value: props.priceCodeId,
            label: pc.name + " (Price: " + pc.price_value + " EUR)"
          })
        })
        .catch((error) => {
          console.error(error)
          setPriceCode(null);
          setError(true);
        })
    } else {
      setPriceCode(null);
    }
  }, [props.priceCodeId]);

  useEffect(() => {

    setLoadOptionsTrigger(Math.random() * 10000.00);

  }, [props.manufacturerId, props.supplierId]);

  const searchPriceCodes = (searchInput: string, page: number): Promise<LoadableSelectItem[]> => {

    return (!props.manufacturerId || !props.supplierId) ? Promise.resolve([]) : PriceService.searchPriceCodes({
      search: searchInput,
      offset: 100 * page,
      limit: 100,
      supplierId: props.supplierId,
      manufacturerId: props.manufacturerId
    })
      .then(response => {
        return response.price_codes.map(
          (priceCode) => {
            return {
              key: priceCode.name,
              value: priceCode.id,
              label: priceCode.name + " (Price: " + priceCode.price_value + " EUR)"
            }
          }
        );
      })
      .catch(error => {
        setError(true);
        throw error;
      });
  };

  return (
    <React.Fragment>
      {
        error && <Alert message="Error loading data" type="error" showIcon closable onClose={() => {
          setError(false)
        }}/>
      }
      <LoadableSelect
        value={priceCode || undefined}
        placeholder="Search price code"
        fetchOptions={searchPriceCodes}
        loadOptionsTrigger={loadOptionsTrigger}
        onChange={(newPriceCode, option) => {
          setPriceCodeAndNotify(option)
        }}
        style={{ width: '100%' }}
      />
    </React.Fragment>
  );
};

export default PriceCodeSelect;
