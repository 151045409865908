import * as React from "react";
import {Alert, Button, Descriptions, Spin, Typography} from 'antd';
import EnhancedTable from "../controls/EnhancedTable/EnhancedTable";
import AssignPermission from "../AssignPermission/AssignPermission";
import EditUser from "../EditUser/EditUser";
import {Permission, UserResponse} from "../../store/user/types";
import "./UserDetailView.css"
import UserService from "../../services/UserService";

const {Text} = Typography;

export interface Props {
    onDeletePermission: (permission: string) => void;
    user: UserResponse;
    loading: boolean;
    error: string;
}

export const UserDetailView: React.FunctionComponent<Props> = (props) => {

    const {user, onDeletePermission, loading, error} = props;

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (item: string) => {
                return (<Text code>{item}</Text>)
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            render: (item: string) => {
                return (<Text>{item}</Text>)
            }
        },
        {
            title: 'Actions',
            dataIndex: '',
            render: (item: any, record: Permission) => {
                return (<Button danger onClick={() => {
                    if (confirm(`Are you sure?`)) {
                        onDeletePermission(record.name)
                    }
                }}>Delete</Button>)
            }
        }
    ];

    return <React.Fragment>

        {
            loading && (<div>
                <div className="view-loading"/>
                <Spin className="view-loading-spinner"/>
            </div>)
        }

        {
            error && (<React.Fragment>
                <Alert
                    message="Error"
                    description={error}
                    type="error"
                />
                <br/>
            </React.Fragment>)
        }
        {!!user &&
        <Descriptions size="small" column={2} bordered>
            <Descriptions.Item label="Username">
                <a>{user.username}</a>
            </Descriptions.Item>
            <Descriptions.Item label="Email">
                <Text>{user.email}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="Created">
                {new Date(user.createdAt).toLocaleString()}
            </Descriptions.Item>
            <Descriptions.Item
                label="Modified">{new Date(user.updatedAt).toLocaleString()}
            </Descriptions.Item>
            <Descriptions.Item label="Country">
                <Text>{user.country}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="Actions">
                <Button danger onClick={() => {
                    if (confirm("Are you sure?")) {
                        UserService.deleteUser(user.email).then(() => {
                            window.location.pathname = `/app/users`
                        }).catch(() => {
                            alert("Make sure you have correct permissions or contact to administrators")
                        })
                    }
                }
                }>Delete user</Button>
            </Descriptions.Item>
        </Descriptions>
        }

        <br/>
        <h2>Assign permission to user</h2>
        <AssignPermission user={user}/>

        {!!user &&
        <EnhancedTable tableName={"Permissions"}
                       columns={columns}
                       data={user.permissions}
                       totalCount={user.permissions.length}
                       countOnPage={user.permissions.length}
                       pageSize={user.permissions.length}
                       currentPage={0}/>
            }

        <br/>
        <header>
            <h2>Update user's country</h2>
            <p className="subtitle">User's permissions are applied to this country. <b>Admin</b> permission by default is applied to all countries regardless of user's country</p>
        </header>
        {!!user &&
        <EditUser user={user}/>}

    </React.Fragment>
};

export default UserDetailView;
