import { all, call, put, takeLatest } from 'redux-saga/effects'

import { CREATE_PRODUCT, CreateProductAction, CreateProductResponse, FETCH_PRODUCT, FetchProductAction } from './types'
import { createProductFailed, createProductSucceeded, fetchProductFailed, fetchProductSucceeded } from './actions'

import productService from '../../services/ProductService'
import { message } from "antd";
import * as React from "react";

export function* productSaga() {
  yield all([
    watchCreateProduct(),
    watchFetchProduct()
  ])
}

function* watchCreateProduct() {
  yield takeLatest(CREATE_PRODUCT, handleCreateProduct)
}

function* watchFetchProduct() {
  yield takeLatest(FETCH_PRODUCT, handleFetchProduct)
}

function* handleCreateProduct(action: CreateProductAction) {
  try {
    const data: CreateProductResponse = yield call([productService, productService.createProduct], action.payload);

    yield put(createProductSucceeded(data));
    message.success((<span>Create A Product request created for product with EAN: {action.payload.product.gtin}.
      Ref ID = {data.refId}</span>), 3).then(() => window.location.reload());
  } catch (error) {
    message.error(error.response.data.title);
    yield put(createProductFailed(`${error.response.data.detail}`))
  }
}

function* handleFetchProduct(action: FetchProductAction) {
  try {
    const data = yield call([productService, productService.fetchProduct], action.payload);
    yield put(fetchProductSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchProductFailed(`${error.message}`))
  }
}

