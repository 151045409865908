import { Reducer } from "redux";
import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_FAILED,
  CREATE_PRODUCT_SUCCEEDED,
  FETCH_PRODUCT,
  FETCH_PRODUCT_FAILED,
  FETCH_PRODUCT_SUCCEEDED,
  ProductActionTypes,
  ProductState
} from "./types";

const initialState: ProductState = {
  newProduct: null,
  existingProduct: null,
  refId: null,
  loading: true,
  error: null
};

export const productReducer: Reducer<ProductState, ProductActionTypes> = (state: ProductState = initialState, action: ProductActionTypes): ProductState => {
  switch (action.type) {

    case CREATE_PRODUCT: {
      return {
        ... state,
        newProduct: action.payload.product,
        loading: true
      }
    }

    case CREATE_PRODUCT_SUCCEEDED: {
      return {
        ...state,
        newProduct: null,
        refId: action.payload.refId,
        loading: false,
        error: null
      }
    }

    case CREATE_PRODUCT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case FETCH_PRODUCT: {
      return {
        ... state,
        existingProduct: null,
        loading: true
      }
    }

    case FETCH_PRODUCT_SUCCEEDED: {
      return {
        ...state,
        existingProduct: action.payload.product,
        loading: false,
        error: null
      }
    }

    case FETCH_PRODUCT_FAILED: {
      return {
        ...state,
        existingProduct: null,
        loading: false,
        error: action.error
      }
    }

    default:
      neverReached(action);
  }

  return state;
};

const neverReached = (never: never) => {};
