import axiosInstance from '../axiosInstance'
import { AxiosInstance } from "axios";
import { resolveApiBaseUrl } from "../environment";
import { Medium } from "../store/medium/medium";
import { Country, LanguageType } from "../store/common/types";

class MediumService {
  axios: AxiosInstance;

  constructor () {
    this.axios = axiosInstance;
  }

  getMediumList(language: LanguageType): Promise<Medium[]> {

    return this.axios.get(`/mediums?language=${language}`, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return response['data'];
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  handleError(error: any) {
    console.error('MediumService:', error)
  }
}

export default new MediumService()
