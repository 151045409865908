import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./ProductSearchListPage.css";
import {Dispatch} from "redux";
import {AppState} from "../../store";
import {flowRight} from "lodash";
import {connect} from 'react-redux';
import {UserSession} from "../../store/userSession/types";
import {PageHeader} from 'antd';
import ProductSearchListView from "../../components/ProductSearchList/ProductSearchListView";
import {
    FetchProductSearchItemsPageRequest,
    FetchProductSearchPageResponse
} from "../../store/productSearch/types";
import {fetchProductSearch} from "../../store/productSearch/actions";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import {Country} from "../../store/common/types";
import {withTranslation} from "react-i18next";

interface Props extends RouteComponentProps {
    userSession: UserSession;

    pageRequest: FetchProductSearchItemsPageRequest;
    pageResponse: FetchProductSearchPageResponse;

    fetchProductSearch: (pageRequest: FetchProductSearchItemsPageRequest) => void;
    loading?: boolean;
    error?: string;
}

interface State {
}

class ProductSearchListPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.onPaginationChanged = this.onPaginationChanged.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
    }

    componentDidMount() {
    }

    private onPaginationChanged(page: number, size: number) {
        console.log("Page: " + page + ", size: " + size);

        this.props.fetchProductSearch({
            ...this.props.pageRequest,
            page,
            size
        })
    }

    private onFilterChanged(filterField: string, value: string) {
        console.log("Filter: " + filterField + ", value: " + value);

        this.props.fetchProductSearch({
            ...this.props.pageRequest,
            [filterField]: value
        })
    }

    public render() {

        // @ts-ignore
        const {t} = this.props;

        return (
            <div className="import-tasks-page">
                <PageWrapper userSession={this.props.userSession}>
                    <PageHeader
                        ghost={false}
                        onBack={() => window.history.back()}
                        title= {t("product.searchPage.title")}
                        subTitle= {t("product.searchPage.subtitle")}
                    >
                    </PageHeader>
                    <ProductSearchListView
                        pageRequest={this.props.pageRequest}
                        pageResponse={this.props.pageResponse}
                        onPaginationChanged={this.onPaginationChanged}
                        onFilterChanged={this.onFilterChanged}
                        onRowClick={this.onRowClick}
                        loading={this.props.loading}
                        error={this.props.error}/>
                </PageWrapper>
            </div>
        );
    }

    private onRowClick(country:Country, productId: number) {
        this.props.history.push("/app/products/" +country+ "/" + productId);
    }
}

const mapStateToProps = (state: AppState) => ({
    userSession: state.userSession.userSession,
    pageRequest: state.productSearch.pageRequest,
    pageResponse: state.productSearch.pageResponse,
    loading: state.productSearch.loading,
    error: state.productSearch.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchProductSearch: (pageRequest: FetchProductSearchItemsPageRequest) => {
        dispatch(fetchProductSearch(pageRequest))
    }
});

const enhance = flowRight(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
);

export default withTranslation()(enhance(ProductSearchListPage));
