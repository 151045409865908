import axiosInstance from '../axiosInstance'
import { AxiosInstance } from "axios";
import { resolveApiBaseUrl } from "../environment";
import * as qs from "qs";
import { Supplier, SupplierGetRequest, SupplierSearchRequest, SupplierSearchResponse } from "../store/supplier/type";

class SupplierService {
  axios: AxiosInstance;

  constructor () {
    this.axios = axiosInstance;
  }

  getSupplier(request: SupplierGetRequest): Promise<Supplier> {

    const params = {
      country: request.country,
      manufacturerId: request.manufacturerId,
      productType: request.productType
    } as any;

    return this.axios.get(`/gateway/mdng-getter-api/suppliers/${request.supplierId}`, {
        baseURL: resolveApiBaseUrl(),
        params: params,
        paramsSerializer: {
            serialize: params => {
            return qs.stringify(params, {
                arrayFormat: 'repeat'
            })
        }
    }
    })
      .then((response: any) => {
        return {
          ...response['data']
        } as Supplier;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  searchSuppliers(request: SupplierSearchRequest): Promise<SupplierSearchResponse> {

    const params = {
      manufacturerId: request.manufacturerId,
      search: request.search,
      country: request.country,
      page: request.page,
      size: request.size,
      productType: request.productType
    } as any;

    params.sort = "supplierId,ASC";

    return this.axios.get(`/gateway/mdng-getter-api/suppliers`, {
        baseURL: resolveApiBaseUrl(),
        params: params,
        paramsSerializer: {
            serialize: params => {
            return qs.stringify(params, {
                arrayFormat: 'repeat'
            })
        }
    }
    })
      .then((response: any) => {
        return {
          ...response['data']
        } as SupplierSearchResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  handleError(error: any) {
    console.error('SupplierService:', error)
  }
}

export default new SupplierService()
