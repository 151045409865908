import * as React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "../../pages/HomePage/HomePage";
import "./App.css";
import { Provider } from "react-redux";
import { history, store } from "../../store";

import { fetchUserSession, fetchUserSessionSilent } from "../../store/userSession/actions";
import { UserSession, USER_SESSION_REFRESH_INTERVAL } from "../../store/userSession/types";
import Login from "../Login/Login";
import SettingsPage from "../../pages/SettingsPage/SettingsPage";
import { ConnectedRouter } from "connected-react-router";
import ProductPage from "../../pages/ProductPage/ProductPage";
import { Spin } from "antd";
import UsersPage from "../../pages/UsersPage/UsersPage";
import UserPage from "../../pages/UsersPage/UserPage";
import AddUserPage from "../../pages/UsersPage/AddUserPage";
import ProductSearchListPage from "../../pages/ProductSearchListPage/ProductSearchListPage";
import CAPOffline from "../../pages/CAPOffline/CAPOffline";

interface State {
  userSession: UserSession,
  loadingUserSession: boolean,
  error: string
}

class App extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      userSession: null,
      loadingUserSession: true,
      error: null
    };

    store.subscribe(() => {
      this.setState({
        userSession: store.getState().userSession.userSession,
        loadingUserSession: store.getState().userSession.loading,
        error: store.getState().userSession.error
      });
    });

    // don't load session info in offline mode
    if (!window.location.pathname.startsWith("/app/cap-offline")) {
      store.dispatch(fetchUserSession());

      // Refresh the userSession in the backend
      setInterval(() => {
        store.dispatch(fetchUserSessionSilent());
      }, USER_SESSION_REFRESH_INTERVAL);
    }
  }

  public render() {
    const { userSession, loadingUserSession, error } = this.state;

    if (loadingUserSession && !window.location.pathname.startsWith("/app/cap-offline")) {
      return (
        <div>
          <Spin className="view-loading-spinner" size={"large"}/>
        </div>
      );
    }

    if ((userSession && !userSession.loggedIn) && !window.location.pathname.startsWith("/app/cap-offline")) {
      return (
          <Login/>
      );
    }

    if (error) {
      return (
        <div>
          {error}
        </div>
      );
    }

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div className="app">
            <Switch>

              <Route
                  exact
                  path="/app/cap-offline"
                  render={() => <CAPOffline/>}
              />

              <Route
                exact
                path="/"
                render={() => <HomePage/>}
              />

              <Route
                exact
                path="/app/products/create"
                render={() => <ProductPage/>}
              />

              <Route
                exact
                path="/app/products/:country/:productId"
                render={() => <ProductPage/>}
              />

              <Route
                  exact
                  path={'/app/products/search'}
                  render={() => <ProductSearchListPage/>}
              />

              <Route
                exact
                path={'/app/settings'}
                render={() => <SettingsPage/>}
              />

              <Route
                  exact
                  path={'/app/users'}
                  render={() => <UsersPage/>}
              />

              <Route
                  exact
                  path={'/app/user'}
                  render={() => <UserPage/>}
              />

              <Route
                  exact
                  path={'/app/user/add'}
                  render={() => <AddUserPage/>}
              />
            </Switch>
          </div>
        </ConnectedRouter>
      </Provider>
    );
  }

}

export default App;
