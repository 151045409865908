import * as React from "react";
import {LoadableSelect, LoadableSelectItem} from "../controls/LoadableSelect";
import PermissionService from "../../services/PermissionService";
import {Button, Col, Form, Row} from "antd";
import {UserResponse} from "../../store/user/types";
import {GetPermissionResponse} from "../../store/permission/types";

async function searchPermissions(searchInput: string, page: number): Promise<LoadableSelectItem[]> {

    return PermissionService.findPermissions({
        search: searchInput,
        offset: 100 * page,
        limit: 100,
    })
        .then(response => {
            return response.permissions.map(
                (permission: GetPermissionResponse) => {
                    return {
                        label: permission.name + " -- " + permission.description,
                        value: permission.name
                    }
                }
            );
        });
}

export interface Props {
    user: UserResponse;
}

export const AssignPermission: React.FunctionComponent<Props> = (props) => {

    const {user} = props;

    const [form] = Form.useForm();

    const onSubmit = (data: any) => {
        PermissionService.assignPermissions({
            username: user.email,
            permission: data.permission.value}).then(() => {
                window.location.reload()
        }).catch((e) => {
            alert(`${e.message}: Check your permissions or contact to administrator`)
        });
    }

    return (
        <React.Fragment>
            <Form
                layout={'horizontal'}
                form={form}
                onFinish={onSubmit}>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item name={"permission"}>
                            <LoadableSelect
                                placeholder="Search permission"
                                onSelect={(value: { value: any; }) => {
                                    console.log("Permission selected", value.value)
                                }}
                                fetchOptions={(search: string, page: number) => searchPermissions(search, page)}
                                style={{width: '100%'}}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Assign
                            </Button>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
        </React.Fragment>
    );
};

export default AssignPermission;
