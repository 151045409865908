import {UserSession} from "../../store/userSession/types";
import * as React from "react";
import {ReactNode} from "react";
import "./PageWrapper.css"
import {Layout, Menu, Select} from 'antd';
import {BarcodeOutlined, HomeOutlined, SettingOutlined} from '@ant-design/icons';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons/lib";
import UserIcon from "../UserIcon/UserIcon";
import LanguageIcon from "../LanguageIcon/LanguageIcon";

const {SubMenu} = Menu;

interface Props {
    userSession: UserSession;
    children: ReactNode;
}

const {Header, Sider, Content} = Layout;

export default function PageWrapper(props: Props) {

    const [collapsed, setCollapsed] = React.useState(false);

    const { t } = useTranslation();

    // Menu data
    const mainMenuItems: any[] = [
        {
            key: "1",
            name: t("menu.home"),
            url: "/",
            icon: <HomeOutlined/>
        },
        {
            key: "2",
            name: t("menu.product"),
            icon: <BarcodeOutlined/>,
            subItems: [
                {
                    key: "2-1",
                    name: t("menu.create"),
                    url: "/app/products/create"
                },
                {
                    key: "2-3",
                    name: t("menu.searchEdit"),
                    url: "/app/products/search"
                }
            ]
        }
    ];

    const secondaryMenuItems: any[] = [
        {
            key: "3",
            name: t("menu.settings"),
            icon: <SettingOutlined/>,
            subItems: [
                {
                    key: "3-1",
                    name: t("menu.users"),
                    url: "/app/users"
                },
                {
                    key: "3-2",
                    name: t("menu.addUser"),
                    url: "/app/user/add"
                }
            ]
        }
    ];

    let location = useLocation();
    const mainItem = mainMenuItems.filter(item => {
        if (item.url === location.pathname) {
            return true;
        } else {
            const subItemFound = item.subItems && item.subItems.filter((subItem: any) => subItem.url === location.pathname)[0];
            return !!subItemFound;
        }
    })[0];

    const subItemFound = mainMenuItems.reduce((acc: [], item) => acc.concat(item.subItems || []), []).filter((subItem: any) => subItem.url === location.pathname)[0];

    const secondaryItem = secondaryMenuItems.filter(item => item.url === location.pathname)[0];
    const key = mainItem && mainItem.key || secondaryItem && secondaryItem.key;
    const subKey = subItemFound && subItemFound.key;

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    return (

        <Layout style={{
            height: '100vh'
        }}>
            <Sider trigger={null} collapsible collapsed={collapsed} onCollapse={toggle}>
                <div className="logo"/>
                <Menu theme="dark" mode="inline" selectedKeys={[key, subKey]} defaultOpenKeys={["2", "6", "4", "8"]}>
                    {
                        mainMenuItems.map((item) => {
                            if (props.userSession.permissions && (props.userSession.permissions.indexOf('Admin') > -1 ||
                                props.userSession.permissions && props.userSession.permissions.indexOf('User') > -1)) {
                                return item.subItems
                                    ?
                                    (
                                        <SubMenu key={item.key} title={item.name} icon={item.icon}>
                                            {item.subItems.map((subItem: any) => {
                                                return (
                                                    <Menu.Item key={subItem.key}>
                                                        <Link to={subItem.url}>
                                                            <span>{subItem.name}</span>
                                                        </Link>
                                                    </Menu.Item>
                                                )
                                            })}
                                        </SubMenu>
                                    )
                                    :
                                    (
                                        <Menu.Item key={item.key}>
                                            <Link to={item.url}>
                                                {item.icon}
                                                <span>{item.name}</span>
                                            </Link>
                                        </Menu.Item>
                                    )
                            }
                        })
                    }
                    <Menu.Divider/>
                    {
                        secondaryMenuItems.map((item) => {
                            if (props.userSession.permissions && props.userSession.permissions.indexOf('Admin') > -1) {
                                return item.subItems
                                    ?
                                    (
                                        <SubMenu key={item.key} title={item.name} icon={item.icon}>
                                            {item.subItems.map((subItem: any) => {
                                                return (
                                                    <Menu.Item key={subItem.key}>
                                                        <Link to={subItem.url}>
                                                            <span>{subItem.name}</span>
                                                        </Link>
                                                    </Menu.Item>
                                                )
                                            })}
                                        </SubMenu>
                                    )
                                    :
                                    (
                                        <Menu.Item key={item.key}>
                                            <Link to={item.url}>
                                                {item.icon}
                                                <span>{item.name}</span>
                                            </Link>
                                        </Menu.Item>
                                    )
                            }
                        })
                    }
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{padding: 0}}>
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: toggle,
                    })}
                    <div style={{display: 'inline-flex', fontSize: '18px', color: 'rgba(0, 0, 0, 0.85)'}}><strong>{t("cap.title")}</strong></div>
                    <UserIcon style={{float: 'right', paddingRight: '30px'}} userSession={props.userSession}/>
                    <LanguageIcon style={{float: 'right', paddingRight: '30px'}} />
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '2px 2px',
                        padding: 24,
                        minHeight: 'unset',
                        overflowY: 'auto'
                    }}
                >
                    {props.children}
                </Content>
            </Layout>
        </Layout>
    );
}
